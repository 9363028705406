import React, { createContext, FC, ReactNode, useCallback, useState } from "react";
import { Confirmation } from "../components/Confirmation";

export interface ConfirmationProps {
  title: string;
  message?: ReactNode;
  confirm: () => void;
}

export interface ConfirmationContextValue {
  setConfirmationProps: (value: ConfirmationProps) => void;
  showConfirmation: () => any;
}

export const ConfirmationContext = createContext<ConfirmationContextValue>({
  setConfirmationProps: () => {},
  showConfirmation: () => {},
});

export const ConfirmationProvider: FC = ({ children }) => {
  const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps>({
    title: "",
    confirm: () => {},
  });

  const [show, setShow] = useState(false);
  const showConfirmation = useCallback(() => setShow(true), [setShow]);
  const cancel = useCallback(() => {
    setShow(false);
    setConfirmationProps({ title: "", message: "", confirm: () => {} });
  }, [setShow, setConfirmationProps]);
  const confirm = useCallback(() => {
    confirmationProps.confirm();
    setShow(false);
  }, [confirmationProps.confirm, setShow]);

  return (
    <ConfirmationContext.Provider value={{ setConfirmationProps, showConfirmation }}>
      {children}
      <Confirmation
        show={show}
        title={confirmationProps.title}
        message={confirmationProps.message}
        confirm={confirm}
        cancel={cancel}
      />
    </ConfirmationContext.Provider>
  );
};
