import React, { FC } from "react";

export const Loader: FC = ({ children }) => {
  return (
    <div className="x_content" style={{ height: 200 }}>
      <div className="page-loader" style={{ position: "absolute" }} />
      <div style={{ marginTop: 180 }}>{children}</div>
    </div>
  );
};
