import _ from "lodash";

import { ActiveFilters, ActiveFiltersWithGroupByInterval } from "../types/Filters";

export const isActiveFiltersEqual = (
  activeFilters: ActiveFilters | ActiveFiltersWithGroupByInterval,
  other: ActiveFilters | ActiveFiltersWithGroupByInterval
) => {
  if (activeFilters === other) return true;

  const activeFiltersCopy = { ...activeFilters };
  const otherCopy = { ...other };

  const arrayFields = ["partnerCodes", "partnerTags", "formCodes", "markets", "browsers"] as const;

  arrayFields.forEach((key) => {
    if (activeFilters[key]) activeFiltersCopy[key] = _.sortBy(activeFilters[key]);

    if (other[key]) otherCopy[key] = _.sortBy(other[key]);
  });

  return _.isEqual(activeFiltersCopy, otherCopy);
};
