import React from "react";
import PropTypes from "prop-types";
import { FormControl, Glyphicon, HelpBlock } from "react-bootstrap";

export const getValidationState = (
  touched: boolean,
  invalid: boolean,
  error: string,
  warning: string
) => {
  if (touched && invalid) {
    if (warning) return "warning";

    return "error";
  }

  return null;
};

export const getValidationGlyph = (
  touched: boolean,
  invalid: boolean,
  error: string,
  warning: string
) => {
  if (touched && invalid) {
    let glyph = "remove";

    if (warning) glyph = "warning-sign";

    return (
      <FormControl.Feedback>
        <Glyphicon glyph={glyph} />
      </FormControl.Feedback>
    );
  }

  return null;
};

export const getInfoHelpBlock = (show: boolean, msg: string) => {
  if (!show) return null;

  return msg && <HelpBlock>{msg}</HelpBlock>;
};

export const getHelpBlock = (touched: boolean, error: string, warning?: string) => {
  if (!touched) return null;

  if (error && warning)
    return (
      <HelpBlock>
        {error}
        <br />
        {warning}
      </HelpBlock>
    );
  else if (error || warning) return <HelpBlock>{error || warning}</HelpBlock>;

  return null;
};
