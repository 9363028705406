import axios, { AxiosError } from "axios";

import { BASE_API_URL } from "consts";
import { store } from "store";
import { logout } from "actions/auth";
import { delay } from "utils/misc";

const MAX_RETRY_COUNTS = 2;
const RETRY_DELAY = 1000;

interface RetryConfig {
  currentRetryAttempt: number;
  refreshTokenRetry?: boolean;
}

declare module "axios" {
  export interface AxiosRequestConfig {
    retryConfig?: RetryConfig;
  }
}

export const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  return {
    ...config,
    baseURL: BASE_API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt") || ""}`,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error.response?.status === 403 && localStorage.getItem("jwt")) {
      try {
        const { data } = await axiosInstance.post<{ accessToken: string }>("/auth/refresh-token");
        localStorage.setItem("jwt", data.accessToken);

        return axiosInstance.request({
          ...error.config,
          data: typeof data === "string" ? JSON.parse(data) : data,
        });
      } catch (error) {
        store.dispatch(logout());
      }
    }

    throw error;
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      store.dispatch(logout());
      return;
    }

    throw error;
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const currentRetryAttempt = error.config.retryConfig?.currentRetryAttempt || 0;

    if (error.response?.status && currentRetryAttempt < MAX_RETRY_COUNTS) {
      const { data } = error.config;

      await delay(RETRY_DELAY);

      return axiosInstance.request({
        ...error.config,
        data: typeof data === "string" ? JSON.parse(data) : data,
        retryConfig: {
          currentRetryAttempt: currentRetryAttempt + 1,
        },
      });
    }

    throw error;
  }
);
