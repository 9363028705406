import React from "react";
import PropTypes from "prop-types";

export default class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    return (
      this.props.display && (
        <a
          href=""
          className="user-profile dropdown-toggle"
          onClick={this.handleClick}
          style={this.props.style || {}}
        >
          {this.props.children}
        </a>
      )
    );
  }
}
