import { STATUS_FAILURE, STATUS_SUCCESS } from "../actions/status";
import { createOrUpdateById } from "../utils/array";

const reduceFilterRules = (fr) => {
  const ret = {};

  if (fr instanceof Array) {
    fr.forEach((e) => (ret[e.key] = e));
  }

  return ret;
};

const reduceCreatives = (creatives) => {
  if (!creatives || creatives.length === 0) {
    return creatives;
  }

  return creatives.map((c) => ({
    ...c,
    filterRules: reduceFilterRules(c.filterRules || []),
  }));
};

export default function (state, action) {
  switch (action.status) {
    case STATUS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: Date.now(),
        success: true,
        data: action.data,
      };

    case STATUS_FAILURE:
      return {
        isFetching: false,
        success: false,
      };

    default:
      return {
        isFetching: true,
        success: false,
      };
  }
}

export function pushOrUpdateOrganization(state, action) {
  const { organization } = action;
  const items = state.data ? state.data.items : null;

  if (!items || !state.success) {
    return {
      ...state,
      data: {
        ...state.data,
        items: [organization],
      },
    };
  }

  return {
    ...state,
    data: {
      ...state.data,
      items: createOrUpdateById(items, organization),
    },
  };
}

export function pushOrUpdateUser(state, action) {
  const { user } = action;

  const items = state.data ? state.data.users : null;

  if (!items || !state.success) {
    return {
      ...state,
      data: {
        ...state.data,
        users: [user],
      },
    };
  }

  return {
    ...state,
    data: {
      ...state.data,
      users: createOrUpdateById(items, user),
    },
  };
}

export function configReducer(state, action) {
  switch (action.status) {
    case STATUS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: Date.now(),
        success: true,
        data: action.data,
      };

    case STATUS_FAILURE:
      return {
        isFetching: false,
        success: false,
      };

    default:
      return {
        isFetching: true,
        success: false,
      };
  }
}

export function simpleSuccessReducer(state, action) {
  switch (action.status) {
    case STATUS_SUCCESS:
      return action.data;

    default:
      return state;
  }
}

export function statsReducer(state, action) {
  switch (action.status) {
    case STATUS_SUCCESS:
      return {
        ...state,
        stats: action.data,
      };
    default:
      return {
        ...state,
        stats: {},
      };
  }
}
