import { LOGIN, LOGOUT, CLEAR_LAST_ERROR_CODE } from "../actions/auth";

import {
  AuthState,
  loginReducer,
  expirationReducer,
  logout,
  switchUserReducer,
  getInitialState,
  switchToInitialUser,
  ActionWithStatus,
} from "./authReducer";
import { PUSH_ORGANIZATION } from "../actions/organizations";
import { SWITCH_USER, SWITCH_TO_INITIAL_USER, PUSH_USER } from "../actions/users";

import { createOrUpdateById } from "../utils/array";

export default function (
  state: AuthState = getInitialState(),
  action: ActionWithStatus<
    | typeof LOGOUT
    | typeof LOGIN
    | typeof SWITCH_USER
    | typeof SWITCH_TO_INITIAL_USER
    | typeof PUSH_ORGANIZATION
    | typeof PUSH_USER
    | typeof CLEAR_LAST_ERROR_CODE
  >
) {
  switch (action.type) {
    case LOGOUT:
      return logout();

    case LOGIN:
      return loginReducer(state, action);

    case SWITCH_USER:
      return switchUserReducer(state, action);

    case SWITCH_TO_INITIAL_USER:
      return switchToInitialUser(state);

    case PUSH_ORGANIZATION:
      if (!state.user || !state.user.superAdmin || !state.organizations) return state;
      return {
        ...state,
        organizations: createOrUpdateById(state.organizations, action.organization),
      };

    case PUSH_USER:
      if (!state.user || !state.user.superAdmin) return state;

      if (state.user.id !== action.user.id) {
        return state;
      }

      let user = { ...action.user };
      delete user.organisations;

      if (user.disabled) {
        return logout();
      }

      return {
        ...state,
        user,
      };
    case CLEAR_LAST_ERROR_CODE: {
      return {
        ...state,
        lastErrorCode: null,
      };
    }

    default:
      return expirationReducer(state, action);
  }
}
