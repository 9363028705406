import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { Form, Button, ButtonToolbar, Row, Col, Modal } from "react-bootstrap";
import { memoize, sortBy } from "lodash";

import FormTextRowField from "../../components/FormTextRowField.react";

import { fetchUser, newUser } from "../../actions/users";

import { FORM_NAME } from "./constants";

// TODO: move validators to common path
import { required, minLength, maxLength } from "../campaign/validation/validate";
import { getUserOrganizations } from "../../selectors/app";
import { getCurrentUserData, getCurrentUserFetchStatus } from "../../selectors/users";
import { getManagementStatus } from "../../selectors/app";
import FormCheckboxField from "../../components/FormCheckboxField.react";
import { FormSelectField } from "../../components/FormSelectField";

import { validateEmail } from "../../utils/validation";
import { axiosInstance } from "../../api/axiosInstance";
import { showErrorNotification, showSuccessNotification } from "../../utils/notifications";

class UserDialog extends Component {
  constructor() {
    super();
  }

  isUpdate() {
    return !!this.props.id;
  }

  fetchSuccessful(oldProps, nextProps) {
    return oldProps.isFetching && !nextProps.isFetching && nextProps.success;
  }

  fetchFailed(oldProps, nextProps) {
    return oldProps.isFetching && !nextProps.isFetching && !nextProps.success;
  }

  componentDidMount() {
    const { loadUser, newUser } = this.props;
    this.isUpdate() ? loadUser() : newUser();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.fetchSuccessful(this.props, nextProps)) {
      let initalValues = { ...(nextProps.user || {}) };
      if (!this.isUpdate()) {
        initalValues = Object.assign({}, this.props.initialValues);
      }

      initalValues.organizationDistributionPartnerCode =
        initalValues.organization?.distributionPartnerCode;

      this.props.initialize(initalValues);
    }
  }

  onSubmit = async (values) => {
    let { onSubmit } = this.props;
    let isUpdate = this.isUpdate();

    Object.keys(values)
      .filter((key) => typeof values[key] === "string")
      .forEach((key) => (values[key] = values[key].trim()));

    try {
      const request = isUpdate
        ? axiosInstance.put(`/admin/users/${values.id}`, values)
        : axiosInstance.post("/admin/add-user", values);

      const { data } = await request;

      showSuccessNotification("User saved");

      onSubmit(data);
    } catch (error) {
      if (!error) {
        showErrorNotification(
          "Error saving user",
          "Please check that the form is filled in correctly and try again"
        );
        throw new SubmissionError({
          _error: "There was a network error, please try again later.",
        });
      } else if (error) {
        throw new SubmissionError({
          domain: "User email already exists. You cannot have multiple users with the same email",
        });
      }
    }
  };

  renderFooter() {
    const { onCancel, submitting, management } = this.props;

    return (
      <ButtonToolbar className="pull-right">
        <Button type="submit" bsStyle="success" disabled={submitting || !management}>
          {this.isUpdate() ? "Update" : "Create"}
        </Button>
        <Button onClick={() => onCancel()} bsStyle="danger">
          Cancel
        </Button>
      </ButtonToolbar>
    );
  }

  renderFields() {
    return (
      <div className="x_content">
        <Row>
          <Field
            name="name"
            component={FormTextRowField}
            type="text"
            label="User Name"
            placeholder="User Name"
            labelStyle={{ xs: 12, md: 2, sm: 2, lg: 2, xl: 2 }}
            controlStyle={{ xs: 12, md: 10, sm: 10 }}
            validate={[required, minLength(1), maxLength(50)]}
          />

          <Field
            name="email"
            label="Email"
            type="text"
            component={FormTextRowField}
            placeholder=""
            labelStyle={{ xs: 12, md: 2, sm: 2, lg: 2, xl: 2 }}
            controlStyle={{ xs: 12, md: 10, sm: 10 }}
            disabled={this.isUpdate()}
            required
            validate={this.isUpdate() ? [required] : [required, validateEmail]}
          />

          {!this.isUpdate() && (
            <Field
              name="password"
              component={FormTextRowField}
              type="password"
              label="Password"
              placeholder="Password"
              required
              labelStyle={{ xs: 12, md: 2, sm: 2, lg: 2, xl: 2 }}
              controlStyle={{ xs: 12, md: 10, sm: 10 }}
              validate={[required, isValidPassword, minLength(8), maxLength(50)]}
            />
          )}

          {!this.isUpdate() && (
            <Field
              name="confirmPassword"
              component={FormTextRowField}
              type="password"
              label="Confirm Password"
              placeholder="Confirm Password"
              required
              labelStyle={{ xs: 12, md: 2, sm: 2, lg: 2, xl: 2 }}
              controlStyle={{ xs: 12, md: 10, sm: 10 }}
              validate={[
                required,
                isValidConfirmPassword,
                isValidPassword,
                minLength(8),
                maxLength(50),
              ]}
            />
          )}

          <Field
            name="superAdmin"
            component={FormCheckboxField}
            colLg={2}
            colMd={2}
            colSm={2}
            colXs={12}
            label="SuperAdmin"
          />

          <Field
            name="groupPrincipal"
            component={FormCheckboxField}
            colLg={2}
            colMd={2}
            colSm={2}
            colXs={12}
            label="GroupPrincipal"
          />
          <Field
            name="enabled"
            component={FormCheckboxField}
            colLg={2}
            colMd={2}
            colSm={2}
            colXs={12}
            label="Enabled"
          />
          <Field
            name="organizationDistributionPartnerCode"
            component={FormSelectField}
            options={this.props.organizationOptions}
            validate={[required]}
            labelLayout={{ lg: 2, md: 2, sm: 2, xs: 2 }}
          />
        </Row>
      </div>
    );
  }

  renderLoader() {
    return (
      <div className="x_content" style={{ height: 200 }}>
        <div className="page-loader" style={{ position: "absolute" }} />
      </div>
    );
  }

  render() {
    const { isFetching, submitting, handleSubmit } = this.props;

    return (
      <Modal show animation={false}>
        <Form horizontal onSubmit={handleSubmit(this.onSubmit)}>
          <Modal.Body>
            <Row>
              <div className="x_title">
                <h2>{this.isUpdate() ? "Update" : "Add"} user</h2>
                <div className="clearfix" />
              </div>
              {isFetching || submitting ? this.renderLoader() : this.renderFields()}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col xs={12}>{this.renderFooter()}</Col>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

UserDialog.propTypes = {
  id: PropTypes.string,

  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const getOrganizationOptions = memoize((organizations) =>
  sortBy(organizations, ["disabled", "name"]).map((org) => ({
    value: org.partnerCode,
    label: org.name,
  }))
);

const mapStateToProps = (state) => {
  const management = getManagementStatus(state);
  const organizations = getUserOrganizations(state) || [];
  let { isFetching, success } = getCurrentUserFetchStatus(state);

  const user = getCurrentUserData(state);

  return {
    user,
    isFetching,
    success,
    organizationOptions: getOrganizationOptions(organizations),
    management,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let { id } = ownProps;

  return {
    loadUser: () => dispatch(fetchUser(id)),
    newUser: () => dispatch(newUser()),
  };
};

UserDialog = connect(mapStateToProps, mapDispatchToProps)(UserDialog);

export default UserDialog = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: true,
})(UserDialog);

const passwordRegex = /^.{8,}$/;
const isValidPassword = (str) => {
  str = str.trim();
  return passwordRegex.test(str) ? undefined : "Password must be at least eight characters long";
};

const isValidConfirmPassword = (value, allValues) => {
  return value === allValues.password ? undefined : "Passwords don't match";
};
