export const getSelectedModeAndOrganizationId = (state) => {
  const { app = null } = state;
  let distributionpartnercodes = app ? app.distributionpartnercodes || null : null;
  let mode = app ? app.mode || null : null;

  return { mode, distributionpartnercodes };
};

export const getSelectedOrganization = (state) => {
  const { distributionpartnercodes } = getSelectedModeAndOrganizationId(state);
  const organizations = getUserOrganizations(state);

  if (!distributionpartnercodes || !organizations) return null;

  return organizations.find(({ partnerCode }) => partnerCode === distributionpartnercodes) || null;
};

export const getUserOrganizations = (state) => {
  const { auth = null } = state;

  if (!auth) return null;

  let { organizations = null } = auth;

  return organizations || null;
};

export const getUser = (state) => {
  const { auth = null } = state;

  if (!auth) return null;

  let { user = null } = auth;

  return user || null;
};

export const getSuperadminStatus = (state) => {
  const user = getUser(state);

  return user ? !!user.superAdmin : false;
};

export const getManagementStatus = (state) => {
  const user = getUser(state);

  return user ? !!user.management : false;
};

export const getInitialUser = (state) => {
  const { auth = null } = state;

  if (!auth) return null;

  let { initialUser = null } = auth;

  return initialUser || null;
};

export const getUserAuthenticationStatus = (state) => {
  const { auth = null } = state;

  if (!auth) return false;

  return !!auth.isAuthenticated;
};

export const getLastAuthErrorCode = (state) => {
  const { auth = null } = state;

  if (!auth) return null;

  return auth.lastErrorCode;
};

export const getAppConfig = (state) => {
  const app = state.app;

  if (!app || !app.config) return null;

  return app.config;
};
