import { useSelector } from "react-redux";
import { ExtendedOrganization } from "../types/Organization";

export const useUserOrganizations = () => {
  const organizations: ExtendedOrganization[] = useSelector(
    (state: any) => state?.auth?.organizations || []
  );

  return organizations;
};
