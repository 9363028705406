import React from "react";
import PropTypes from "prop-types";

export default () => (
  <div className="app_container body">
    <div className="main_container">
      <div className="col-md-12">
        <div className="col-middle">
          <div className="text-center text-center">loading</div>
        </div>
      </div>
    </div>
  </div>
);
