import { change } from "redux-form";

interface DBEnity {
  id: string | number;
  _id?: string | number;
}

export const updateById = <T extends DBEnity>(
  data: T[],
  id: string,
  changes: Partial<T>,
  key: "id" | "_id" = "id"
) => {
  const index = data.findIndex((item) => item[key] === id);

  if (index >= 0) {
    return [...data.slice(0, index), { ...data[index], ...changes }, ...data.slice(index + 1)];
  }

  return data;
};

export const createOrUpdateById = <T extends DBEnity>(
  data: T[],
  entity: T,
  key: "id" | "_id" = "id"
) => {
  const index = data.findIndex((item) => item[key] === entity[key]);

  if (index >= 0) {
    return [...data.slice(0, index), entity, ...data.slice(index + 1)];
  }

  return [...data, { ...entity }];
};
