import React from "react";
import { Table } from "react-bootstrap";
import { Field } from "redux-form";
import FormTextRowField from "../../../components/FormTextRowField.react";
import { required, minLength, maxLength } from "../../campaign/validation/validate";
import { last } from "lodash";

const COLUMNS = [
  { field: "id", fieldName: "ID" },
  { field: "formCode", fieldName: "FormCode" },
  { field: "productName", fieldName: "ProductName" },
  { field: "url", fieldName: "URL" },
  { field: "extensionUrl", fieldName: "Ext_CWS_URL" },
];

export const renderFormCodes = ({ fields, meta: { error } }) => {
  const formCodes = fields.getAll() || [];
  const lastFormcodeId = Number(last(formCodes)?.id) || 0;

  return (
    <center>
      <Table striped bordered hover className="editable-table">
        <thead>
          <tr>
            {COLUMNS.map((column) => {
              return <th key={column.field}>{column.fieldName}</th>;
            })}
            <th>
              <button
                type="button"
                onClick={() =>
                  fields.push({ id: lastFormcodeId + 1, formCode: "", productName: "", url: "" })
                }
                className="custom-table__action-btn"
              >
                <i className="fa fa-plus" />
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {fields.map((formcode, index) => {
            return (
              <tr key={index}>
                <td style={{ width: "25px" }}>{formCodes[index].id || index}</td>
                <td style={{ width: "120px" }}>
                  <Field
                    name={`${formcode}.formCode`}
                    type="text"
                    component={FormTextRowField}
                    controlStyle={{ xs: 12, md: 10, sm: 12, lg: 12 }}
                    required
                    validate={[required, minLength(4), maxLength(8)]}
                  />
                </td>
                <td style={{ width: "167px" }}>
                  <Field
                    name={`${formcode}.productName`}
                    type="text"
                    component={FormTextRowField}
                    controlStyle={{ xs: 12, md: 10, sm: 12, lg: 12 }}
                    required
                    validate={[required, minLength(4), maxLength(20)]}
                  />
                </td>
                <td>
                  <Field
                    name={`${formcode}.url`}
                    type="text"
                    component={FormTextRowField}
                    placeholder={""}
                    controlStyle={{ xs: 12, md: 10, sm: 12, lg: 12 }}
                    required
                    validate={[required, minLength(1), maxLength(50)]}
                  />
                </td>
                <td>
                  <Field
                    name={`${formcode}.extensionUrl`}
                    type="text"
                    component={FormTextRowField}
                    placeholder={""}
                    controlStyle={{ xs: 12, md: 10, sm: 12, lg: 12 }}
                    validate={[minLength(1)]}
                  />
                </td>

                <td style={{ width: "31px" }}>
                  <button
                    type="button"
                    onClick={() => fields.remove(index)}
                    className="custom-table__action-btn"
                  >
                    <i className="fa fa-trash-o" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {error && <p className="text-danger">{error}</p>}
    </center>
  );
};
