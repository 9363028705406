import { replace } from "react-router-redux";
import { showErrorNotification } from "../utils/notifications";

import { commonApi } from "../api/commonApi";
import { STATUS_LOADING, STATUS_SUCCESS, STATUS_FAILURE } from "./status";
import { axiosInstance } from "../api/axiosInstance";
import { ThunkAction, ThunkActionDispatch } from "redux-thunk";
import { Dispatch } from "redux";
import { RootState } from "store";

const AUTH_ERROR_CODE = 0x1000;
const STATUS_AUTH_NO_ACCESS = AUTH_ERROR_CODE | 0x7;
const STATUS_AUTH_ACCESS_LEVEL_LOW = AUTH_ERROR_CODE | 0x8;

export const isAuthErrorCode = (code: number | null) =>
  code !== null && (AUTH_ERROR_CODE & code) === AUTH_ERROR_CODE;

export const higherLevelAccessRequired = (code: number) =>
  isAuthErrorCode(code) && (code & STATUS_AUTH_ACCESS_LEVEL_LOW) === code;

export const LOGIN = "LOGIN";
export function login(user: string, password: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOGIN, status: STATUS_LOADING });
    try {
      const {
        data: { accessToken },
      } = await axiosInstance.post<{ accessToken: string }>("/auth/login", {
        login: user,
        password,
      });

      localStorage.setItem("jwt", accessToken);
      const { data } = await axiosInstance.get("/user/me");

      dispatch({ type: LOGIN, status: STATUS_SUCCESS, payload: data });
    } catch (error) {
      showErrorNotification("Login failed");
      dispatch({ type: LOGIN, status: STATUS_FAILURE, payload: error });
    }
  };
}

export function tokenLogin() {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOGIN, status: STATUS_LOADING });

    try {
      const { data } = await axiosInstance.get("/user/me");

      dispatch({ type: LOGIN, status: STATUS_SUCCESS, payload: data });
    } catch (error) {
      localStorage.removeItem("jwt");
      dispatch({ type: LOGIN, status: STATUS_FAILURE, payload: error });
    }
  };
}

export function checkToken(redir: string) {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { auth } = getState();

    if (!auth.token) {
      dispatch(replace(redir));
      return;
    }

    dispatch({ type: LOGIN, status: STATUS_LOADING });

    try {
      const { data } = await axiosInstance.get("/user/me");
      dispatch({ type: LOGIN, status: STATUS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: LOGIN, status: STATUS_FAILURE, payload: error });
    }
  };
}

export const LOGOUT = "LOGOUT" as const;
export function logout(): ThunkAction<
  Promise<void>,
  RootState,
  undefined,
  { type: typeof LOGOUT }
> {
  return async (dispatch: Dispatch) => {
    localStorage.removeItem("jwt");
    dispatch(commonApi.util.resetApiState());
    dispatch({
      type: LOGOUT,
    });
  };
}

export const CLEAR_LAST_ERROR_CODE = "CLEAR_LAST_ERROR_CODE";
export function clearLastErrorCode() {
  return {
    type: CLEAR_LAST_ERROR_CODE,
  };
}
