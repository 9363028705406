import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DateRangePicker from "./DateRangePicker";

export default ({
  input,
  endDate,
  startDate,
  minDate,
  maxDate,
  emptyStartDate,
  disabled,
  onDateRangeSet,
}) => {
  const startDateValue = !emptyStartDate ? moment(startDate) : null;
  const endDateValue = !emptyStartDate ? moment(endDate) : null;

  const onChange = (value) => {
    onDateRangeSet();
    input.onChange(value);
  };

  const format = "YYYY-MM-DD";

  return (
    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
      <label>Select date/range:</label>
      <DateRangePicker
        ranges={{}}
        startDate={startDateValue}
        endDate={endDateValue}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        onDateChange={(v) => onChange(v)}
        format={format}
        className="pull-right"
      />
    </div>
  );
};
