import { STATUS_FAILURE, STATUS_LOADING, STATUS_SUCCESS } from "./status";
import { axiosInstance } from "../api/axiosInstance";

export const CHANGE_USER_LISTING_PARAMS = "CHANGE_USER_LISTING_PARAMS";
export const changeUserListingParams = (
  offset = 0,
  limit = 10,
  search = "",
  sort = "name",
  order = "asc"
) => ({
  type: CHANGE_USER_LISTING_PARAMS,
  offset,
  limit,
  search,
  sort,
  order,
});

export const FETCH_USERS = "FETCH_USERS";
export const fetchUsers = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_USERS, status: STATUS_LOADING });

    try {
      const { data } = await axiosInstance.get("/admin/users");
      // can't name data as `payload` because users reducer shares logic with others
      dispatch({ type: FETCH_USERS, status: STATUS_SUCCESS, data });
    } catch (error) {
      dispatch({ type: FETCH_USERS, status: STATUS_FAILURE, payload: error });
    }
  };
};

export const NEW_USER = "NEW_USER";
export const newUser = () => ({
  type: NEW_USER,
  status: STATUS_SUCCESS,
  data: {},
});

export const FETCH_USER = "FETCH_USER";
export const fetchUser = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_USER, status: STATUS_LOADING });

    try {
      const { data } = await axiosInstance.get(`/admin/users/${id}`);

      dispatch({ type: FETCH_USER, status: STATUS_SUCCESS, data });
    } catch (error) {
      dispatch({ type: FETCH_USER, status: STATUS_FAILURE, payload: error });
    }
  };
};

export const SWITCH_USER = "SWITCH_USER";
export const switchUser = (id) => async (dispatch) => {
  dispatch({ type: SWITCH_USER, status: STATUS_LOADING });

  try {
    const { data } = await axiosInstance.get(`/admin/users/${id}/token`);
    dispatch({ type: SWITCH_USER, status: STATUS_SUCCESS, data });
  } catch (err) {
    dispatch({ type: SWITCH_USER, status: STATUS_FAILURE, err });
  }
};

export const SWITCH_TO_INITIAL_USER = "SWITCH_TO_INITIAL_USER";
export const switchToInitialUser = () => {
  return {
    type: SWITCH_TO_INITIAL_USER,
  };
};

export const PUSH_USER = "PUSH_USER";
export const pushUser = (user) => {
  return {
    type: PUSH_USER,
    user,
  };
};
