import React from "react";
import { connect } from "react-redux";
import { flowRight } from "lodash";
import { withRouter } from "react-router";

import { setCurrentLocationConfig } from "../actions/app";
import { getManagementStatus, getSuperadminStatus } from "../selectors/app";

export default function requireSuperuser(Component, Component401, RequiredResource) {
  class ResourceWrap extends React.Component {
    UNSAFE_componentWillMount() {
      const { setCurrentLocationConfig, location } = this.props;

      const currentResource = location.pathname.split("/").slice(1).join("/");
      setCurrentLocationConfig(currentResource, RequiredResource);
    }

    render() {
      const { canAccess } = this.props;
      return canAccess ? <Component {...this.props} /> : <Component401 {...this.props} />;
    }
  }

  const mapStateToProps = (state) => {
    const superAdmin = getSuperadminStatus(state);
    const management = getManagementStatus(state);

    return {
      canAccess: !!superAdmin,
      management,
    };
  };

  const mapDispatchToProps = { setCurrentLocationConfig };

  return flowRight(connect(mapStateToProps, mapDispatchToProps), withRouter)(ResourceWrap);
}
