import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import SimplifiedTopNavigation from "../navigation/SimplifiedTopNavigation";

const appver = require("../../../../package.json").version;
// resources
import ImgAptGlobal from "../../../images/apptitude-global-logo.png";

export default (props) => {
  document.body.className = "nav-sm";

  return (
    <div className="app_container body">
      <div className="main_container">
        <div className="col-md-3 left_col">
          <div className="left_col scroll-view">
            <div className="navbar nav_title" style={{ border: 0 }}>
              <a className="site_title">
                <img src={ImgAptGlobal} alt="Apptitude" />
              </a>
            </div>

            <div className="clearfix" />
            <br />
          </div>
        </div>

        <SimplifiedTopNavigation user={props.user} dispatch={props.dispatch} />

        {/* page content*/}
        <div className="right_col" role="main" style={{ position: "relative", minWidth: 950 }}>
          {/* main content*/}
          <Row>
            <Col md={12} sm={12} xs={12}>
              <div className="dashboard_graph x_panel">
                <div className="x_content">
                  <h2>Down for maintenance</h2>
                  <p>We are currently undergoing scheduled maintenance and will be back shortly.</p>
                  <p>
                    We apologize for the inconvenience and appreciate your patience <br />
                    The Ividence team.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          {/* /main content*/}
        </div>
        {/* /page content*/}

        {/* footer content*/}
        <footer>
          <div className="pull-right">{appver + " beta"} </div>
          <div className="clearfix" />
        </footer>
        {/* /footer content*/}
      </div>
    </div>
  );
};
