import React from "react";
import moment from "moment";
import BSDateRangePicker from "react-bootstrap-daterangepicker";
import { Button, Glyphicon } from "react-bootstrap";
import "react-bootstrap-daterangepicker/css/daterangepicker.css";

export default class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);

    const { startDate, endDate } = this.processDates(this.props);

    this.state = {
      ranges: props.ranges || {
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Previous Month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
        "Last 3 Months": [
          moment().startOf("month").subtract(2, "month").startOf("month"),
          moment(),
        ],
        "Last 6 Months": [
          moment().startOf("month").subtract(5, "month").startOf("month"),
          moment(),
        ],
        "This Year": [moment().startOf("year"), moment()],
        Reset: [moment().subtract(29, "days").startOf("day"), moment().startOf("day")],
      },
      startDate,
      endDate,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { startDate, endDate } = this.props;

    if (startDate !== nextProps.startDate || endDate !== nextProps.endDate) {
      let { startDate, endDate } = this.processDates(nextProps);
      this.setState({
        startDate,
        endDate,
      });
    }
  }

  processDates({ startDate, endDate, format }) {
    let parsedStartDate = moment(startDate, format, true);
    let parsedEndDate = moment(endDate, format, true);

    return {
      startDate: parsedStartDate,
      endDate: parsedEndDate,
    };
  }

  onApply(event, picker) {
    const reset = picker.chosenLabel === "Reset";

    const { onChange, startDate, endDate } = this.props;

    if (reset && startDate === "" && endDate === "") {
      return;
    }

    if (onChange) {
      onChange({
        startDate:
          !reset && picker.startDate.isValid() ? picker.startDate.format(this.props.format) : "",
        endDate: !reset && picker.endDate.isValid() ? picker.endDate.format(this.props.format) : "",
      });
    } else {
      const newStartDate = !reset && picker.startDate.isValid() ? picker.startDate : "";
      const newEndDate = !reset && picker.endDate.isValid() ? picker.endDate : "";
      this.setState({
        startDate: newStartDate,
        endDate: newEndDate,
      });
      this.props.onDateChange(
        `${newStartDate.format("YYYY-MM-DD")},${newEndDate.format("YYYY-MM-DD")}`
      );
    }
  }

  render() {
    const attrib = {};
    let label = "Choose date";

    if (this.state.startDate.isValid() && this.state.endDate.isValid()) {
      const start = this.state.startDate.format(this.props.format);
      const end = this.state.endDate.format(this.props.format);

      label = start + " - " + end;

      if (start === end) {
        label = start;
      }

      attrib.startDate = this.state.startDate;
      attrib.endDate = this.state.endDate;
    }

    if (this.props.minDate) {
      attrib.minDate = this.props.minDate;
    }

    if (this.props.maxDate) {
      attrib.maxDate = this.props.maxDate;
    }

    attrib.ranges = this.state.ranges;
    attrib.opens = "left";

    const className = this.props.className || "";

    return (
      <div style={{ display: "inline-block" }} className={className}>
        <BSDateRangePicker onApply={(e, t) => this.onApply(e, t)} {...attrib}>
          <Button id="reportrange" className="white-dropdown-button" disabled={this.props.disabled}>
            <Glyphicon glyph="calendar" />
            <span style={{ padding: "0 5px" }}>{label}</span>
            <b className="caret" />
          </Button>
        </BSDateRangePicker>
      </div>
    );
  }
}
