import { getTiersData } from "./OrganizationDialog/helpers";

export const FORM_NAME = "organizationForm";

export const ORGANIZATIONS_RESOURCES = {
  "pb/stats/mainreporting": "main",
  "pb/stats/ptagreporting": "ptag",
  "pb/stats/mainreportingv2": "mainv2",
  "pb/stats/ptagreportingv2": "ptagv2",
  "pb/stats/ptqsreporting": "ptqs",
  "pb/stats/referral": "referral",
  rsearches: "filtering",
};

export const DEFAULT_TIERS = {
  tier0: { revshare: "70.00", limit: 150000 },
  tier1: { revshare: "72.00", limit: 360000 },
  tier2: { revshare: "74.00", limit: -1 },
};

const DEFAULT_ALL_TIERS = getTiersData({
  revshare_tiers: DEFAULT_TIERS,
  gross_rev_tiers: DEFAULT_TIERS,
  search_count_tiers: DEFAULT_TIERS,
});

export const INITIAL_ORGANIZATION_FORM_VALUES = {
  enabled: true,
  filtering: true,
  main: true,
  ptag: false,
  mainv2: false,
  ptagv2: false,
  ptqs: false,
  referral: false,
  tempest_coverage: false,
  filtering_threshold: "25%",
  send_email_alerts: {
    send_alert: true,
    emails: [],
  },
  cumulative_volumes: false,
  formCodes: [
    {
      id: 1,
      formCode: "",
      productName: "",
      url: "",
      extensionUrl: "",
    },
  ],
  ...DEFAULT_ALL_TIERS,
  tiers_type: "none",
};
