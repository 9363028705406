import { SELECT_APP_MODE_AND_ORGANIZATION } from "../actions/app";

import { LOGIN } from "../actions/auth";
import { STATUS_SUCCESS } from "../actions/status";

export default function (
  state = {
    config: {},
    navMenu: {},
    locationConfig: {},
    modes: [],
    mode: null,
    distributionpartnercodes: null,
  },
  action
) {
  switch (action.type) {
    case SELECT_APP_MODE_AND_ORGANIZATION:
      return {
        ...state,
        mode: action.mode,
        distributionpartnercodes: action.organizationId,
      };

    case LOGIN:
      let config = {};

      if (action.status === STATUS_SUCCESS) {
        config = action.payload.appConfig;

        return {
          ...state,
          config: {
            ...config,
          },
        };
      }

      return state;

    default:
      return state;
  }
}
