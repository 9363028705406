import { CHANGE_USER_LISTING_PARAMS, FETCH_USER, FETCH_USERS } from "../actions/users";
import fetchReducer, { pushOrUpdateUser } from "./fetchReducer";
import { NEW_USER, PUSH_USER } from "../actions";

export default function (
  state = {
    items: {},
    current: {},
    settings: {
      offset: 0,
      limit: 25,
      search: "",
      sort: "name",
      order: "asc",
    },
  },
  action
) {
  switch (action.type) {
    case CHANGE_USER_LISTING_PARAMS:
      return {
        ...state,
        settings: {
          offset: action.offset,
          limit: action.limit,
          search: action.search,
          sort: action.sort,
          order: action.order,
        },
      };

    case FETCH_USER:
    case NEW_USER:
      return {
        ...state,
        current: fetchReducer(state.current, action),
      };

    case FETCH_USERS:
      return {
        ...state,
        current: {},
        items: fetchReducer(state.items, action),
      };

    case PUSH_USER:
      return {
        ...state,
        items: pushOrUpdateUser(state.items, action),
      };

    default:
      return state;
  }
}
