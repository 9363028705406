export const SHOW_MODAL = "SHOW_MODAL";
export const showModal = (type, props) => {
  return {
    type: SHOW_MODAL,
    modalType: type,
    modalProps: props,
  };
};

export const HIDE_MODAL = "HIDE_MODAL";
export const hideModal = (type, params) => {
  return {
    type: HIDE_MODAL,
  };
};
