import Cookie from "js-cookie";
import { STATUS_FAILURE, STATUS_SUCCESS, STATUS_LOADING } from "../actions/status";
import { higherLevelAccessRequired, isAuthErrorCode } from "../actions/auth";
import { User } from "../types/User";
import { Organization } from "../types/Organization";

export interface ActionWithStatus<Type = string> {
  type: Type;
  status?: typeof STATUS_FAILURE | typeof STATUS_SUCCESS | typeof STATUS_LOADING;
  // TODO: figure out neater way to pass payload
  [payload: string]: any;
}

export interface AuthState {
  initialUser?: { id: string; name: string; token: string } | null;
  token?: string | null;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  user?: User | null;
  organizations?: Organization[];
  lastErrorCode?: number | null;
}

export function loginReducer(state: AuthState, action: ActionWithStatus) {
  switch (action.status) {
    case STATUS_SUCCESS:
      let initialUser = state.initialUser;

      if (state.initialUser && state.initialUser.id === action.payload.user.id) {
        initialUser = null;

        Cookie.remove("initial_token", { path: "/" });
        Cookie.remove("initial_name", { path: "/" });
        Cookie.remove("initial_id", { path: "/" });
      }

      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        token: action.payload.token || state.token,
        user: action.payload.user,
        initialUser,
        organizations: action.payload.organizations,
        partnersWithTempestCoverageData: action.payload.partnersWithTempestCoverageData,
      };

    case STATUS_FAILURE:
      return logout();

    case STATUS_LOADING:
      return {
        ...state,
        isAuthenticating: true,
      };

    default:
      return logout();
  }
}

export function expirationReducer(state: AuthState, action: ActionWithStatus) {
  switch (action.status) {
    case STATUS_FAILURE:
      let { status } = action.err || {};

      if (higherLevelAccessRequired(status)) {
        return {
          ...state,
          lastErrorCode: status,
        };
      } else if (isAuthErrorCode(status)) {
        return logout();
      }

      return state;

    default:
      return state;
  }
}

export function switchUserReducer(state: AuthState, action: ActionWithStatus) {
  switch (action.status) {
    case STATUS_SUCCESS:
      if (state.token && state.user) {
        Cookie.set("initial_token", state.token, { path: "/" });
        Cookie.set("initial_name", state.user.name, { path: "/" });
        Cookie.set("initial_id", state.user.id, { path: "/" });
        Cookie.set("token", action.data, { path: "/" });
      }

      return getInitialState();
    case STATUS_FAILURE:
      return logout();

    default:
      return state;
  }
}

export function switchToInitialUser(state: AuthState) {
  if (!state.initialUser || !state.initialUser.token) {
    return logout();
  }

  let token = state.initialUser.token;
  let newState = logout();

  newState.token = token;
  Cookie.set("token", token, { path: "/" });
  return newState;
}

export const logout = () => {
  localStorage.removeItem("jwt");

  Cookie.remove("initial_token", { path: "/" });
  Cookie.remove("initial_name", { path: "/" });
  Cookie.remove("initial_id", { path: "/" });

  return getInitialState();
};

export const getInitialState = (): AuthState => {
  let initialToken = Cookie.get("initial_token") || null;
  let initialName = Cookie.get("initial_name") || null;
  let initialId = Cookie.get("initial_id") || null;

  let initialUser = null;

  if (initialName && initialToken && initialId) {
    initialUser = {
      id: initialId,
      name: initialName,
      token: initialToken,
    };
  }

  return {
    isAuthenticating: false,
    isAuthenticated: false,
    user: null,
    organizations: [],
    token: localStorage.getItem("jwt") || null,
    initialUser: initialUser,
    lastErrorCode: null,
  };
};
