import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { isEmpty } from "lodash";
import Col from "react-bootstrap/es/Col";
import FormGroup from "react-bootstrap/es/FormGroup";
import connect from "react-redux/es/connect/connect";
import { formValueSelector } from "redux-form";
import ControlLabel from "react-bootstrap/es/ControlLabel";
import { getHelpBlock } from "../../components/utils";

class LambdasListDropdown extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange(value) {
    this.props.onChangeLambdaName(value.value);
    this.props.input.onChange(value);
  }

  render() {
    const {
      input,
      lambdas,
      initialValue,
      meta: { submitFailed, touched, error, warning },
    } = this.props;

    const lambdasProcessed = [];

    lambdas.forEach((lambda) => {
      lambdasProcessed.push({
        value: lambda,
        label: lambda,
      });
    });

    const selectAttrs = {
      onChange: (value) => this.onChange(value),
      isSearchable: true,
      noOptionsMessage: ({ inputValue }) => "No results found",
      options: lambdasProcessed,
    };

    const colourStyles = {
      control: (styles) => ({ ...styles, borderColor: "red" }),
    };

    const helpBlock = getHelpBlock(touched, error, warning, undefined);

    if (submitFailed && error) {
      selectAttrs.styles = colourStyles;
    }

    if (!isEmpty(initialValue)) {
      selectAttrs.defaultValue = initialValue;
    }

    return (
      <FormGroup validationState={submitFailed && error ? "error" : null}>
        <Col
          componentClass={ControlLabel}
          xs={1}
          style={{ width: "104px", marginRight: "5px", paddingRight: "0px" }}
        >
          Target function
        </Col>
        <Col xs={9} className="pull-right" style={{ width: "480px" }}>
          <Select {...selectAttrs} />
          {helpBlock}
        </Col>
      </FormGroup>
    );
  }
}

const mapStateToProps = (state) => {
  const initialValue = formValueSelector("InvokeLambdasForm")(state, "lambdas") || {};
  return {
    initialValue,
  };
};

export default connect(mapStateToProps)(LambdasListDropdown);
