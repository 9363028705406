import React from "react";
import { FormControl, FormGroup, ControlLabel, Col } from "react-bootstrap";

import { getValidationState, getHelpBlock, getInfoHelpBlock } from "./utils";

export default class FormTextRowField extends React.Component {
  constructor() {
    super();

    this.input = null;
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    const {
      input: { onChange: inputOnChange, value },
    } = this.props;

    //if the user deletes a char we allow it
    // if (this.input.value.length < value.length) {
    //     inputOnChange(this.input.value);
    // }

    //const charsLeft = this.getCharactersLeft(this.input.value);

    // if (charsLeft < 0) {
    //     return;
    // }

    inputOnChange(this.input.value);
  }

  getCharactersLeft(value) {
    const { maxAllowedCharacters } = this.props;

    if (maxAllowedCharacters <= 0) {
      return 1;
    }

    return maxAllowedCharacters - value.length;
  }

  getCountdownMsg() {
    const {
      showCountdown,
      input: { value = "" },
    } = this.props;

    if (!showCountdown) {
      return null;
    }

    const charsLeft = this.getCharactersLeft(value);
    return `${charsLeft} characters left`;
  }

  render() {
    const {
      input,
      label,
      rightLabel,
      rightLabelIcon,
      meta: { touched, invalid, error, warning },
      componentClass,
      textareaStyle,
      value,
      formGroupStyle,
      controlClass,
      controlStyles,
      labelStyles,
      labelStyle,
      rightLabelStyles,
      rightLabelStyle,
      inputWidth,
      controlStyle,
      controlMargin,
      required,
      onClick,
      ...custom
    } = this.props;

    const {
      xl: lblXl = 3,
      lg: lblLg = 3,
      md: lblMd = 3,
      sm: lblSm = 3,
      xs: lblXs = 12,
    } = labelStyle || {};
    const {
      xl: ctlXl = 6,
      lg: ctlLg = 6,
      md: ctlMd = 6,
      sm: ctlSm = 6,
      xs: ctlXs = 12,
    } = controlStyle || {};
    const {
      xl: rightLabelXl = 1,
      lg: rightLabelLg = 1,
      md: rightLabelMd = 1,
      sm: rightLabelSm = 1,
      xs: rightLabelXs = 1,
    } = rightLabelStyle || {};

    let validationState = getValidationState(touched, invalid, error, warning);
    let attrib = label
      ? {}
      : {
          className: "editable-table-form-group",
        };

    if (validationState) {
      attrib.validationState = validationState;
    }

    const attrs = {
      ...input,
      ...custom,
      onChange: this.onChange,
    };

    let controlLabelStyle = controlMargin
      ? { marginLeft: controlMargin || "0px" }
      : labelStyles || {};
    let inputStyle = inputWidth ? { width: inputWidth || "400px" } : {};

    if (componentClass === "textarea") {
      attrs.componentClass = componentClass;
      attrs.style = { ...inputStyle, ...textareaStyle };
    } else {
      attrs.style = inputStyle;
    }

    attrib.style = formGroupStyle;

    delete attrs.maxAllowedCharacters;
    delete attrs.showCountdown;

    const msg = this.getCountdownMsg();
    const helpBlock =
      getHelpBlock(touched, error, warning, msg) ||
      getInfoHelpBlock(this.input === document.activeElement, msg);

    const hasRightLabel = rightLabel || rightLabelIcon;

    if (custom.type === "hidden") {
      return <input type="hidden" {...input} />;
    }

    return (
      <FormGroup {...attrib}>
        {label && (
          <Col
            componentClass={ControlLabel}
            style={controlLabelStyle}
            xl={lblXl}
            lg={lblLg}
            md={lblMd}
            sm={lblSm}
            xs={lblXs}
          >
            {label}
          </Col>
        )}
        <Col
          className={label ? controlClass || "none" : "editable-table-col"}
          xl={ctlXl}
          lg={ctlLg}
          md={ctlMd}
          sm={ctlSm}
          xs={ctlXs}
          style={hasRightLabel && !controlStyles ? { width: "360px" } : controlStyles || {}}
        >
          <FormControl {...attrs} inputRef={(ref) => (this.input = ref)} />
          {helpBlock}
        </Col>
        {hasRightLabel && (
          <Col
            xl={rightLabelXl}
            lg={rightLabelLg}
            md={rightLabelMd}
            sm={rightLabelSm}
            xs={rightLabelXs}
            style={rightLabelStyles ? rightLabelStyles : { width: "70px" }}
          >
            <label className="expand-label">
              {rightLabelIcon && (
                <i onClick={onClick} className={rightLabelIcon} style={{ marginRight: "5px" }}></i>
              )}
              {rightLabel && <a onClick={onClick}>{rightLabel}</a>}
            </label>
          </Col>
        )}
      </FormGroup>
    );
  }
}
