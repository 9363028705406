import React from "react";
import { Col, ControlLabel, FormGroup } from "react-bootstrap";
import { WrappedFieldProps } from "redux-form";
import Select from "react-select";
import { Styles } from "react-select/lib/styles";
import { OptionsType } from "react-select/lib/types";

import { getHelpBlock } from "./utils";

type Option = { value: string; label: string };

interface LayoutProps {
  lg?: number;
  md?: number;
  sm?: number;
  xs?: number;
}

interface Props extends WrappedFieldProps {
  options: OptionsType<Option>;
  label: string;
  disabled?: boolean;

  labelLayout?: LayoutProps;
  controlLayout?: LayoutProps;
}

export const FormSelectField = (props: Props) => {
  const {
    input,
    options,
    label,
    disabled,
    labelLayout,
    controlLayout,
    meta: { submitFailed, touched, error, warning },
  } = props;

  const { lg: lblLg = 3, md: lblMd = 3, sm: lblSm = 3, xs: lblXs = 12 } = labelLayout || {};
  const { lg: ctlLg = 6, md: ctlMd = 6, sm: ctlSm = 6, xs: ctlXs = 12 } = controlLayout || {};

  const errorStyles: Styles = {
    control: (styles) => ({ ...styles, borderColor: "red" }),
  };

  const helpBlock = getHelpBlock(touched, error, warning);

  const value: Option | undefined =
    typeof input.value === "string"
      ? options.find(({ value }) => value === input.value)
      : (input.value as Option);

  return (
    <FormGroup validationState={submitFailed && error ? "error" : null}>
      <Col componentClass={ControlLabel} lg={lblLg} md={lblMd} sm={lblSm} xs={lblXs}>
        {label}
      </Col>
      <Col lg={ctlLg} md={ctlMd} sm={ctlSm} xs={ctlXs}>
        <Select
          name={input.name}
          value={value}
          onChange={(nextValue) =>
            input.onChange(nextValue ? (nextValue as Option).value : nextValue)
          }
          onFocus={input.onFocus}
          resetInputOnBlur={false}
          closeResetsInput={false}
          options={options}
          styles={submitFailed && error ? errorStyles : {}}
          noOptionsMessage={() => "No results found"}
          isSearchable
          isDisabled={disabled}
        />
        {helpBlock}
      </Col>
    </FormGroup>
  );
};
