import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Button, ButtonToolbar, Row, Col, Modal } from "react-bootstrap";

import { connect } from "react-redux";
import { reduxForm, Field, initialize, destroy } from "redux-form";

import { required } from "../campaign/validation/validate";
import FormDateRangePickerReact from "../../components/FormDateRangePicker.react";
import FormTextRowField from "../../components/FormTextRowField.react";
import LambdasListDropdown from "./LambdasListDropdown";

import { axiosInstance } from "../../api/axiosInstance";
import moment from "moment";

const FORM_NAME = "InvokeLambdasForm";

class InvokeLambdasDialog extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      lambdaName: null,
      displayJobID: true,
      displayDatePicker: true,
      disabledDateRange: false,
      isDateRangeSet: false,
      lambdasNames: null,
    };
  }

  componentDidMount = async () => {
    const { onCancel } = this.props;

    try {
      const { data } = await axiosInstance.get("/get-all-lambdas");

      this.setState({
        lambdasNames: data.lambdaList,
        isLoading: false,
      });
    } catch {
      console.log("Error occured during the lambdas name fetch");
      onCancel();
    }
  };

  componentWillUnmount() {
    const { destroyForm } = this.props;
    destroyForm(FORM_NAME);
  }

  onChangeLambdaName(value) {
    const disabledDateRange = value.includes("Hourly");
    const displayJobID =
      value.includes("Daily") || value.includes("Hourly") || value.includes("Accumulators");

    const displayDatePicker =
      (value.includes("Daily") && !value.includes("Validator")) ||
      value.includes("Gross") ||
      value.includes("Net");

    this.setState({
      lambdaName: value,
      disabledDateRange,
      displayDatePicker,
      displayJobID,
    });
  }

  onDateRangeSet() {
    this.setState({
      isDateRangeSet: true,
    });
  }

  onSubmit(values) {
    const submitData = {
      lambdaName: values.lambdas.value,
      isAsyncCall: false,
    };

    if (values.date_range) {
      submitData.lambdaParams = {
        startDate: values.date_range.split(",")[0],
        endDate: values.date_range.split(",")[1],
      };
      if (values.jobID) submitData.lambdaParams.jobID = values.jobID;
    }

    this.props.onSubmit(submitData);
  }

  renderFooter(lambdaType) {
    const { onCancel } = this.props;

    return (
      <Row>
        <ButtonToolbar className="pull-right">
          <Button
            type="submit"
            bsStyle={"success"}
            disabled={this.state.displayDatePicker && !this.state.isDateRangeSet}
          >
            Execute
          </Button>
          <Button onClick={() => onCancel()} bsStyle={"danger"}>
            Cancel
          </Button>
        </ButtonToolbar>
      </Row>
    );
  }

  render() {
    const { isLoading, lambdaType, displayDatePicker, displayJobID, disabledDateRange } =
      this.state;
    const { handleSubmit } = this.props;

    return (
      <Modal show animation={false} dialogClassName="invoke-lambdas-modal">
        <Form horizontal onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Modal.Body>
            <Row>
              <div className="x_title">
                <h2>Invoke lambda dialog</h2>
                <div className="clearfix" />
              </div>
              {isLoading ? (
                <div className="x_content" style={{ height: 200 }}>
                  <div className="page-loader" style={{ position: "absolute" }} />
                </div>
              ) : (
                <div>
                  <Field
                    name={"lambdas"}
                    component={LambdasListDropdown}
                    lambdas={this.state.lambdasNames}
                    onChangeLambdaName={this.onChangeLambdaName.bind(this)}
                    validate={[required]}
                  />
                  {displayDatePicker && (
                    <Field
                      name={"date_range"}
                      component={FormDateRangePickerReact}
                      minDate={moment().startOf("M")}
                      maxDate={moment()}
                      onDateRangeSet={this.onDateRangeSet.bind(this)}
                      lambdaType={lambdaType}
                      disabled={disabledDateRange}
                      emptyStartDate={!disabledDateRange}
                    />
                  )}

                  {displayJobID && (
                    <Field
                      name="jobID"
                      component={FormTextRowField}
                      type="text"
                      label="JobID (optional)"
                      placeholder="Type jobID here"
                      formGroupStyle={{ marginTop: "19px" }}
                      labelStyles={{ paddingRight: "0px", width: "110px" }}
                      controlStyles={{ width: "153px" }}
                      controlClass={"pull-right"}
                      labelStyle={{ xs: 12, md: 2, sm: 2, lg: 1 }}
                      controlStyle={{ xs: 12, md: 9, sm: 9 }}
                    />
                  )}
                </div>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col xs={12}>{this.renderFooter(lambdaType)}</Col>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

InvokeLambdasDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    initializeForm: (data) => dispatch(initialize(FORM_NAME, data)),
    destroyForm: (form) => dispatch(destroy(form)),
  };
};

InvokeLambdasDialog = connect(mapStateToProps, mapDispatchToProps)(InvokeLambdasDialog);

export default InvokeLambdasDialog = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
})(InvokeLambdasDialog);
