import React from "react";
import PropTypes from "prop-types";
import { Checkbox, FormGroup, ControlLabel, Col } from "react-bootstrap";
import { getValidationState, getHelpBlock } from "./utils";

export default ({
  isFrontLabel = true,
  input,
  label,
  labelStyle,
  meta: { touched, invalid, error, warning },
  colLg = 3,
  colMd = 3,
  colSm = 3,
  colXs = 12,
  md = 3,
  sm = 3,
  xs = 12,
  required,
  noMargin = false,
  checkboxesMarginEnabled,
  checkboxesMargin,
  ...custom
}) => {
  let validationState = getValidationState(touched, invalid, error, warning);
  let attrib = {};

  if (validationState) {
    attrib.validationState = validationState;
  }

  let style = checkboxesMarginEnabled ? { marginLeft: checkboxesMargin || "20px" } : {};

  if (noMargin) style.marginBottom = 0;

  return (
    <FormGroup {...attrib} style={style}>
      {isFrontLabel && (
        <Col
          componentClass={ControlLabel}
          lg={colLg}
          md={colMd}
          sm={colSm}
          xs={colXs}
          style={labelStyle}
        >
          {label} {required && "*"}
        </Col>
      )}
      <Col md={md} sm={sm} xs={xs}>
        <Checkbox
          checked={!!input.value}
          onChange={(e, v) => input.onChange(!input.value)}
          {...custom}
        >
          {!isFrontLabel && <span style={{ fontWeight: "bold" }}>{label}</span>}
        </Checkbox>
        {getHelpBlock(touched, error, warning)}
      </Col>
    </FormGroup>
  );
};
