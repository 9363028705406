import { combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";
import { hashHistory } from "react-router";
import { History } from "history";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { routerMiddleware, routerReducer } from "react-router-redux";
import { configureStore } from "@reduxjs/toolkit";

// @ts-ignore
import userReducer from "./reducers/users";
// @ts-ignore
import authReducer from "./reducers/auth";
// @ts-ignore
import appReducer from "./reducers/app";
// @ts-ignore
import modalsReducer from "./reducers/modal";

import { commonApi } from "./api/commonApi";

import { migrate } from "./utils/persistMigration";

import { reportSlice, reportingSettingsSlice } from "./containers/stats/slice";

const configStore = (history: History) =>
  configureStore({
    reducer: persistReducer(
      {
        key: "root",
        storage,
        whitelist: [reportingSettingsSlice.name],
        version: 6,
        migrate,
      },
      combineReducers({
        form: formReducer,
        routing: routerReducer,
        app: appReducer,
        auth: authReducer,
        users: userReducer,
        modal: modalsReducer,
        toastr: toastrReducer,
        [commonApi.reducerPath]: commonApi.reducer,
        [reportSlice.name]: reportSlice.reducer,
        [reportingSettingsSlice.name]: reportingSettingsSlice.reducer,
      })
    ),
    middleware: [thunkMiddleware, commonApi.middleware, routerMiddleware(history)].concat([]),
    devTools: process.env.NODE_ENV !== "production",
  });

export const store = configStore(hashHistory);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
