import { toastr, BasicToastrOptions } from "react-redux-toastr";

export function showSuccessNotification(title: string, message: string = "") {
  toastr.success(title, message);
}

export function showInfoNotification(title: string, message: string = "") {
  toastr.info(title, message);
}

export function showWarningNotification(title: string, message: string = "") {
  toastr.warning(title, message);
}

export function showErrorNotification(
  title: string,
  message: string = "",
  opts?: BasicToastrOptions,
) {
  toastr.error(title, message, opts);
}
