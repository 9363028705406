import React, { FC, ReactNode } from "react";
import { ButtonToolbar, Button, Modal } from "react-bootstrap";

import styles from "./Confirmation.module.scss";

interface Props {
  show: boolean;
  title: string;
  message?: ReactNode;
  confirm: () => void;
  cancel: () => void;
}

export const Confirmation: FC<Props> = ({ title, message, show, confirm, cancel }) => {
  return (
    <Modal show={show} dialogClassName={styles.dialog} onHide={cancel}>
      <Modal.Body>
        <Modal.Title>{title}</Modal.Title>
        {message}
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar className="pull-right">
          <Button bsStyle="success" onClick={confirm}>
            Confirm
          </Button>
          <Button bsStyle="danger" onClick={cancel}>
            Cancel
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  );
};
