import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { flowRight } from "lodash";
import { withRouter } from "react-router";

import GenericMaintenance from "./maintenance/Generic";

import { setOrganizationId } from "../actions/app";
import { checkToken } from "../actions/auth";

export default function requireLogin(
  Component,
  ComponentLoading,
  Component404,
  { loginUrl } = { loginUrl: "/" }
) {
  const AuthWrap = (props) => {
    const { checkToken, isAuthenticated, isAuthenticating, appConfig, organizations, params } =
      props;
    const { distributionpartnercodes } = params;
    const dispatch = useDispatch();

    useEffect(() => {
      if (!isAuthenticated && !isAuthenticating) {
        checkToken({
          pathname: loginUrl,
        });
      }
    });

    useEffect(() => {
      dispatch(setOrganizationId(distributionpartnercodes || null));
    }, [distributionpartnercodes]);

    if (isAuthenticating) return <ComponentLoading {...props} />;

    if (!isAuthenticated) return <Component404 {...props} />;

    if (appConfig.maintenance === true) return <GenericMaintenance {...props} />;

    const organization = organizations?.find(
      ({ partnerCode }) => partnerCode === distributionpartnercodes
    );

    if (!organization) {
      return <Component404 {...props} />;
    }

    return <Component {...props} />;
  };

  const mapStateToProps = (state) => {
    return {
      ...state.auth,
      appConfig: state.app.config,
    };
  };

  const mapDispatchToProps = {
    checkToken,
  };

  return flowRight([connect(mapStateToProps, mapDispatchToProps), withRouter])(AuthWrap);
}
