import { DataSource } from "../types/DataSource";

const mainreportingMetrics = [
  "searches",
  "clicks",
  "impressions",
  "revenue",
  "click_yield",
  "bidded_srpvs",
  "ad_impressions",
  "ad_impression_yield",
  "rpm",
  "cpc",
  "ad_ctr",
  "ctr",
  "coverage",
  "ad_density",
  // "bidded_ctr" - extra field
  "tempest_coverage",
  "initial_searches",
];

const ptagreportingMetrics = [
  "searches",
  "r_searches",
  "ad_ctr",
  "cpc",
  "rpm",
  "revenue",
  "is_follow_on",
  "ad_impressions",
  "clicks",
  "traffic_filtering",
];

const mainreportingV2Metrics = [
  "m_searches",
  "searches",
  "ctr",
  "cpc",
  "rpm",
  "revenue",
  "impressions",
  "clicks",
  // "ad_ctr", "click_yield", "coverage",
  // "ad_impressions_yield", "ad_density", "ad_impressions" - extra fields
];

const ptagreportingV2Metrics = [
  "searches",
  "m_searches",
  "ad_ctr",
  "cpc",
  "rpm",
  "revenue",
  "ad_impressions",
  "clicks",
  // "ctr", "coverage", "impressions", "ad_impressions_yield", "ad_density" - extra fields
];

const ptqsreportingMetrics = ["ptqs"];

const ptqsPerPartnerMetrics = ["ptqs"];

export const metricNames: Record<string, string> = {
  searches: "Searches",
  clicks: "Clicks",
  impressions: "Impressions",
  revenue: "EstimatedRevenue",
  click_yield: "ClickYield",
  bidded_srpvs: "BiddedSRPVs",
  ad_impressions: "AdImpressions",
  ad_impression_yield: "AdImpressionYield",
  rpm: "RPM",
  cpc: "CPC",
  ad_ctr: "AdCTR",
  ctr: "CTR",
  coverage: "Coverage",
  ad_density: "AdDensity",
  r_searches: "rSearches",
  is_follow_on: "IsFollowOn",
  traffic_filtering: "Filtering",
  m_searches: "mSearches",
  ptqs: "PTQS",
  tempest_coverage: "TempestCoverage",
  initial_searches: "InitialSearches",
};

export const getAllMetrics = (dataSource: DataSource): string[] => {
  switch (dataSource) {
    case "mainreporting":
      return mainreportingMetrics;
    case "mainreportingv2":
      return mainreportingV2Metrics;
    case "ptagreporting":
      return ptagreportingMetrics;
    case "ptagreportingv2":
      return ptagreportingV2Metrics;
    case "ptqsreporting":
      return ptqsreportingMetrics;
    case "ptqsPerPartnerReporting":
      return ptqsPerPartnerMetrics;
    default:
      return [];
  }
};

export const getDefaultMetrics = (dataSource: DataSource): string[] => {
  switch (dataSource) {
    case "mainreporting":
      return ["revenue", "searches", "impressions", "rpm"];
    case "ptagreporting":
      return ["revenue", "searches", "r_searches", "ad_impressions", "clicks"];
    default:
      return getAllMetrics(dataSource);
  }
};
