import { createApi } from "@reduxjs/toolkit/query/react";
import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import type { AxiosRequestConfig, AxiosError } from "axios";
import { axiosInstance } from "./axiosInstance";
import { BASE_API_URL } from "../consts";

export const baseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axiosInstance({
        url,
        method,
        data,
        params,
        baseURL: BASE_API_URL,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const commonApi = createApi({
  baseQuery: baseQuery(),
  reducerPath: "api",
  endpoints: (builder) => ({}),
  tagTypes: [
    "IpRules",
    "PartnerRules",
    "PartnerTagReplacement",
    "APIAccessStatus",
    "APIAccessCredentials",
  ],
});
