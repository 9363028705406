import React from "react";
import PropTypes from "prop-types";
import UserDropdown from "./UserDropdown";

export default class TopNavigation extends React.Component {
  render() {
    const { user, dispatch } = this.props;

    return (
      <div className="top_nav" style={{ minWidth: 950 }}>
        <div className="nav_menu">
          <nav>
            <ul className="nav navbar-nav navbar-right">
              <UserDropdown user={user} dispatch={dispatch} />
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

TopNavigation.propTypes = {
  user: PropTypes.object.isRequired,
};
