import { reportingSettingsSlice, ReportingSettings, Columns } from "../containers/stats/slice";

import { getDefaultMetrics } from "./metrics";
import { getDefaultDimensions } from "./dimensions";
import { PersistedState, createMigrate } from "redux-persist";
import { uniq } from "lodash";

type PersistedStateV4 = {
  [reportingSettingsSlice.name]: ReportingSettings;
} & PersistedState;

type PersistedStateV2 = {
  [reportingSettingsSlice.name]: Omit<ReportingSettings, "ptqsPerPartnerReporting">;
} & PersistedState;

type PersistedStateV1 = {
  [reportingSettingsSlice.name]: Omit<
    ReportingSettings,
    "ptqsPerPartnerReporting" | "ptqsreporting"
  >;
} & PersistedState;

const migrations = {
  2: (state?: PersistedStateV1): PersistedStateV2 | undefined => {
    if (!state) return state;

    const { reportingSettingsSlice, ...rest } = state;

    return {
      reportingSettingsSlice: {
        ...reportingSettingsSlice,
        ptqsreporting: {
          metrics: getDefaultMetrics("ptqsreporting"),
          dimensions: getDefaultDimensions("ptqsreporting"),
        },
      },
      ...rest,
    };
  },
  3: (state?: PersistedStateV2): PersistedStateV2 | undefined => {
    if (!state) return state;

    const { reportingSettingsSlice, ...rest } = state;

    const dimensionsRenameMap: Record<string, string> = {
      distributionpartnercode: "partner_code",
      formcode: "form_code",
      devicetype: "device_type",
      websitecountry: "website_country",
      usercountry: "user_country",
      browsername: "browser_name",
      ptag: "partner_tag",
      partnercode: "partner_code",
      adcountry: "ad_country",
      followontype: "follow_on_type",
      partnertag: "partner_tag",
    };

    const metricsRenameMap: Record<string, string> = {
      estimatedrevenue: "revenue",
      clickyield: "click_yield",
      biddedsrpvs: "bidded_srpvs",
      adimpressions: "ad_impressions",
      adimpressionyield: "ad_impression_yield",
      adctr: "ad_ctr",
      rsearches: "r_searches",
      trafficFiltering: "traffic_filtering",
      msearches: "m_searches",
      addensity: "ad_density",
    };

    const rename = ({ dimensions, metrics }: Columns): Columns => ({
      dimensions: dimensions.map((dimension) => dimensionsRenameMap[dimension] || dimension),
      metrics: metrics.map((metric) => metricsRenameMap[metric] || metric),
    });

    return {
      reportingSettingsSlice: {
        mainreporting: rename(reportingSettingsSlice.mainreporting),
        mainreportingv2: rename(reportingSettingsSlice.mainreportingv2),
        ptagreporting: rename(reportingSettingsSlice.ptagreporting),
        ptagreportingv2: rename(reportingSettingsSlice.ptagreportingv2),
        referral: rename(reportingSettingsSlice.referral),
        ptqsreporting: rename(reportingSettingsSlice.ptqsreporting),
      },
      ...rest,
    };
  },
  4: (state?: PersistedStateV2): PersistedStateV4 | undefined => {
    if (!state) return state;

    const { reportingSettingsSlice, ...rest } = state;

    return {
      reportingSettingsSlice: {
        ...reportingSettingsSlice,
        ptqsPerPartnerReporting: {
          dimensions: getDefaultDimensions("ptqsPerPartnerReporting"),
          metrics: getDefaultMetrics("ptqsPerPartnerReporting"),
        },
      },
      ...rest,
    };
  },
  5: (state?: PersistedStateV4): PersistedStateV4 | undefined => {
    if (!state) return state;

    const { reportingSettingsSlice, ...rest } = state;
    const { dimensions, metrics } = reportingSettingsSlice.ptagreporting;

    return {
      reportingSettingsSlice: {
        ...reportingSettingsSlice,
        ptagreporting: {
          dimensions: dimensions,
          metrics: metrics.map((metric) => (metric === "isfollowon" ? "is_follow_on" : metric)),
        },
      },
      ...rest,
    };
  },
  6: (state?: PersistedStateV4): PersistedStateV4 | undefined => {
    if (!state) return state;

    const { reportingSettingsSlice, ...rest } = state;
    const injectDateAndPartnerCode = ({ dimensions, metrics }: Columns): Columns => ({
      dimensions: uniq(["date", "partner_code", ...dimensions]),
      metrics,
    });

    return {
      reportingSettingsSlice: {
        mainreporting: injectDateAndPartnerCode(reportingSettingsSlice.mainreporting),
        mainreportingv2: injectDateAndPartnerCode(reportingSettingsSlice.mainreportingv2),
        ptagreporting: injectDateAndPartnerCode(reportingSettingsSlice.ptagreporting),
        ptagreportingv2: injectDateAndPartnerCode(reportingSettingsSlice.ptagreportingv2),
        referral: reportingSettingsSlice.referral,
        ptqsreporting: reportingSettingsSlice.ptqsreporting,
        ptqsPerPartnerReporting: reportingSettingsSlice.ptqsPerPartnerReporting,
      },
      ...rest,
    };
  },
};

// @ts-ignore
export const migrate = createMigrate(migrations);
