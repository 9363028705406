import React from "react";
import Loadable from "react-loadable";

import Route from "react-router/lib/Route";
import IndexRoute from "react-router/lib/IndexRoute";

import requireLogin from "./containers/requireLogin";
import requireResource from "./containers/requireResource";
import requireSuperuser from "./containers/requireSuperuser";
import requireLoginAdminView from "./containers/requireLoginAdminView";

const LoginPage = Loadable({
  loader: () => import("./containers/LoginPage"),
  loading: () => <h3> </h3>,
});
const SwitchToUser = Loadable({
  loader: () => import("./containers/switchto"),
  loading: () => <h3> </h3>,
});
const NotFound = Loadable({
  loader: () => import("./containers/NotFound"),
  loading: () => <h3> </h3>,
});
const ReportingLayout = Loadable({
  loader: () => import("./containers/ReportingLayout"),
  loading: () => <h3> </h3>,
});
const MainAppAdminView = Loadable({
  loader: () => import("./containers/MainAppAdminView"),
  loading: () => <h3> </h3>,
});
const Loading = Loadable({
  loader: () => import("./containers/Loading"),
  loading: () => <h3> </h3>,
});
const UserListPage = Loadable({
  loader: () => import("./containers/users/UserListPage"),
  loading: () => <h3> </h3>,
});
const OrganizationListPage = Loadable({
  loader: () => import("./containers/organization/OrganizationListPage"),
  loading: () => <h3> </h3>,
});
const AlertListPage = Loadable({
  loader: () => import("./containers/alerts/AlertListPage"),
  loading: () => <h3> </h3>,
});
const SearchApiStatPage = Loadable({
  loader: () => import("./containers/stats/SearchApiStatPage"),
  loading: () => <h3> </h3>,
});
const BillingPage = Loadable({
  loader: () => import("./containers/billing/BillingPage"),
  loading: () => <h3> </h3>,
});
const ComplianceLayout = Loadable({
  loader: () => import("./containers/ComplianceLayout"),
  loading: () => <h3> </h3>,
});
const JailingAndBlockingPage = Loadable({
  loader: () => import("./containers/compliance/JailingAndBlockingPage"),
  loading: () => <h3> </h3>,
});
const JailingAndBlockingByIPPage = Loadable({
  loader: () => import("./containers/compliance/JailingAndBlockingByIPPage"),
  loading: () => <h3> </h3>,
});
const PTagReplacementPage = Loadable({
  loader: () => import("./containers/compliance/PTagReplacementPage"),
  loading: () => <h3> </h3>,
});
const AccountLayout = Loadable({
  loader: () => import("./containers/account/AccountLayout"),
  loading: () => <h3> </h3>,
});
const InvoicingPage = Loadable({
  loader: () => import("./containers/account/InvoicingPage"),
  loading: () => <h3> </h3>,
});
const ApiAccessPage = Loadable({
  loader: () => import("./containers/account/ApiAccessPage"),
  loading: () => <h3> </h3>,
});

export default (() => [
  <Route>
    <Route path="/">
      <IndexRoute component={LoginPage} />
    </Route>
    <Route path="switchto/:id" component={SwitchToUser} />
    <Route path="/404" component={NotFound} />
    <Route component={requireLoginAdminView(MainAppAdminView, { loginUrl: "/" })} path="/admin">
      <Route
        path="organizations"
        component={requireSuperuser(OrganizationListPage, NotFound, "admin/organizations")}
      />
      <Route path="users" component={requireSuperuser(UserListPage, NotFound, "admin/users")} />
      <Route path="alerts" component={requireSuperuser(AlertListPage, NotFound, "admin/alerts")} />
      <Route path="billing" component={BillingPage} />
    </Route>

    <Route
      path="/compliance"
      component={requireLoginAdminView(ComplianceLayout, Loading, NotFound, { loginUrl: "/" })}
    >
      <Route component={JailingAndBlockingPage} path="jailing_and_blocking" />
      <Route component={JailingAndBlockingByIPPage} path="jailing_and_blocking_by_ip" />
      <Route component={PTagReplacementPage} path="ptag_replacement" />
    </Route>
    <Route
      path="/:distributionpartnercodes/account"
      component={requireLogin(AccountLayout, Loading, NotFound, { loginUrl: "/" })}
    >
      <Route path="invoicing" component={InvoicingPage} />
      <Route path="api_access" component={ApiAccessPage} />
    </Route>

    <Route
      component={requireLogin(ReportingLayout, Loading, NotFound, { loginUrl: "/" })}
      path="/:distributionpartnercodes"
    >
      <Route path="pb">
        <Route
          path="stats/mainreporting"
          component={requireResource(SearchApiStatPage, NotFound, "pb/stats/mainreporting")}
        />
        <Route
          path="stats/mainreportingv2"
          component={requireResource(SearchApiStatPage, NotFound, "pb/stats/mainreportingv2")}
        />
        <Route
          path="stats/ptagreporting"
          component={requireResource(SearchApiStatPage, NotFound, "pb/stats/ptagreporting")}
        />
        <Route
          path="stats/ptagreportingv2"
          component={requireResource(SearchApiStatPage, NotFound, "pb/stats/ptagreportingv2")}
        />
        <Route
          path="stats/ptqsreporting"
          component={requireResource(SearchApiStatPage, NotFound, "pb/stats/ptqsreporting")}
        />
        <Route
          path="stats/referral"
          component={requireResource(SearchApiStatPage, NotFound, "pb/stats/referral")}
        />
      </Route>

      <Route path="*" component={NotFound} />
    </Route>
    <Route path="*" component={NotFound} />
  </Route>,
])();
