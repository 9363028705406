import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { connect, useDispatch } from "react-redux";
import { flowRight } from "lodash";

import { checkToken } from "../actions/auth";
import { setOrganizationId } from "../actions/app";

import { useUserOrganizations } from "hooks/useUserOrganizations";
import { useSelectedPartnerCode } from "hooks/useSelectedPartnerCode";

import { getSuperadminStatus } from "../selectors/app";

import Loading from "./Loading";
import NotFound from "./NotFound";

export default function requireLoginAdminView(Component, { loginUrl } = { loginUrl: "/" }) {
  const AuthWrap = (props) => {
    const { isAuthenticated, isAuthenticating, superAdmin } = props;

    const partnerCode = useSelectedPartnerCode();
    const organizations = useUserOrganizations();

    const dispatch = useDispatch();

    useEffect(() => {
      if (partnerCode === null && organizations?.length) {
        dispatch(setOrganizationId(organizations[0].partnerCode));
      }
    }, [partnerCode, organizations]);

    useEffect(() => {
      if (!isAuthenticated && !isAuthenticating) {
        dispatch(
          checkToken({
            pathname: loginUrl,
          })
        );
      }
    }, [isAuthenticated, isAuthenticating]);

    if (isAuthenticating) return <Loading />;

    if (!isAuthenticated) return <NotFound />;

    if (!superAdmin) return <NotFound />;

    return <Component {...props} />;
  };

  const mapStateToProps = (state) => {
    const superAdmin = getSuperadminStatus(state);

    return {
      ...state.auth,
      appConfig: state.app.config,
      superAdmin,
    };
  };

  return flowRight([connect(mapStateToProps, null), withRouter])(AuthWrap);
}
