import React from "react";

import { Field } from "redux-form";
import { Row } from "react-bootstrap";
// @ts-ignore
import FormTextRowField from "../../../components/FormTextRowField.react";
// TODO: move validators to common path
import { required, minLength, maxLength } from "../../campaign/validation/validate";

interface Props {
  isUpdate: boolean;
}

export const UserFields = ({ isUpdate }: Props) => {
  return (
    <div className="x_content">
      <Row>
        <input type="password" style={{ display: "none" }} />
        <Field
          name="name"
          component={FormTextRowField}
          required
          type={isUpdate ? "hidden" : "text"}
          label="User Name"
          placeholder="User Name"
          labelStyle={{ xs: 12, md: 3, sm: 3, lg: 3 }}
          controlStyle={{ xs: 12, md: 6, sm: 6, lg: 6 }}
          validate={[required, minLength(1), maxLength(50)]}
        />
        <Field
          name="login"
          label="Email"
          type="email"
          component={FormTextRowField}
          placeholder={"Email"}
          labelStyle={{ xs: 12, md: 3, sm: 3, lg: 3 }}
          controlStyle={{ xs: 12, md: 6, sm: 6, lg: 6 }}
          required
          validate={[required, isValidEmail]}
          disabled={isUpdate}
        />
        <Field
          name="password"
          component={FormTextRowField}
          type="password"
          label="Password"
          placeholder="Password"
          required
          labelStyle={{ xs: 12, md: 3, sm: 3, lg: 3 }}
          controlStyle={{ xs: 12, md: 6, sm: 6, lg: 6 }}
          validate={[required, isValidPassword, minLength(8), maxLength(50)]}
        />
        <Field
          name="confirmPassword"
          component={FormTextRowField}
          type="password"
          label="Confirm Password"
          placeholder="Confirm Password"
          required
          labelStyle={{ xs: 12, md: 3, sm: 3, lg: 3 }}
          controlStyle={{ xs: 12, md: 6, sm: 6, lg: 6 }}
          validate={[
            required,
            isValidConfirmPassword,
            isValidPassword,
            minLength(8),
            maxLength(50),
          ]}
        />
      </Row>
    </div>
  );
};

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const isValidEmail = (email: string) => {
  return emailRegex.test(email) ? undefined : "Invalid email";
};

const passwordRegex = /^.{8,}$/;
const isValidPassword = (str: string) => {
  str = str.trim();
  return passwordRegex.test(str) ? undefined : "Password must be at least eight characters long";
};

const isValidConfirmPassword = (value: string, allValues: any) => {
  return value === allValues.password ? undefined : "Passwords don't match";
};
