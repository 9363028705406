import React from "react";
import PropTypes from "prop-types";

export default () => (
  <div className="app_container body">
    <div className="main_container">
      <div className="col-xs-12">
        <div className="col-middle">
          <div className="text-center text-center">
            <h1 className="error-number">404</h1>
            <h2>Sorry but we couldn't find this page</h2>
            <p>The page you are looking for does not exist</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
