import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";

import { setCurrentLocationConfig } from "../actions/app";
import { useSelectedOrganization } from "../hooks/useSelectedOrganization";

export default function requireResource(Component, Component401, RequiredResource) {
  const ResourceWrap = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
      const { location } = props;

      const currentResource = location.pathname.split("/").slice(2).join("/");
      dispatch(setCurrentLocationConfig(currentResource, RequiredResource));
    }, []);

    const selectedOrganization = useSelectedOrganization();

    const canAccess = !!(
      selectedOrganization && selectedOrganization.resources.some((p) => p === RequiredResource)
    );
    return canAccess ? <Component {...props} /> : <Component401 {...props} />;
  };

  return withRouter(ResourceWrap);
}
