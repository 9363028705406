import React, { FC, useEffect, useState } from "react";
import { setBaseURL } from "../consts";

export interface ConfigProps {
  BE_API_HOST: string;
}

export const ConfigProvider: FC<ConfigProps> = ({ BE_API_HOST, children }) => {
  const [_apiUrl, setApiUrl] = useState("");

  useEffect(() => {
    setApiUrl(BE_API_HOST);
    setBaseURL(BE_API_HOST);
  }, [BE_API_HOST]);

  return <>{children}</>;
};
