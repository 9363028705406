import { Organization } from "../types/Organization";
import { commonApi } from "./commonApi";

export const organizationsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizations: builder.query<Organization[], void>({
      query: () => ({
        url: "/admin/organizations",
        method: "GET",
      }),
      transformResponse: (response: { data: { organizations: Organization[] } }) =>
        response.data.organizations,
    }),
  }),
});
