import { useUserOrganizations } from "./useUserOrganizations";
import { useSelectedPartnerCode } from "./useSelectedPartnerCode";

export const useSelectedOrganization = () => {
  const partnerCode = useSelectedPartnerCode();

  const organizations = useUserOrganizations();

  if (!partnerCode) {
    throw Error("No selected organization id");
  }

  if (!organizations.length) {
    throw Error("Organizations list is empty");
  }

  const selectedOrganization = organizations.find((org) => org.partnerCode === partnerCode);

  if (!selectedOrganization) {
    throw Error("No selected organization");
  }

  return selectedOrganization;
};
