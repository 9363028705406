export const FORM_NAME = "userForm";
export const NEW_USER_FORM_NAME = "newUserForm";
export const RESOURCES = [
  {
    id: "pb/stats/mainreporting",
    label: "Monetization Stats SAPI",
    mode: "IFP",
  },
  {
    id: "pb/stats/ptagreporting",
    label: "Monetization Stats PTAG",
    mode: "IFP",
  },
  {
    id: "pb/stats/mainreportingv2",
    label: "Monetization Stats SAPI V2",
    mode: "IFP",
  },
  {
    id: "pb/stats/ptagreportingv2",
    label: "Monetization Stats PTAG V2",
    mode: "IFP",
  },
  {
    id: "public_api",
    label: "API ACCESS",
    mode: "API",
  },
];
