import connect from "react-redux/es/connect/connect";
import React from "react";
import PropTypes from "prop-types";
import InvokeLambdasDialog from "../navigation/InvokeLambdasDialog";
import OrganizationDialog from "../organization/OrganizationDialog";
import UserDialog from "../users/UserDialog";
import { UserFormDialog } from "../users/UserFormDialog";
import { hideModal } from "../../actions/modals";

export const InvokeLambdasDialogType = "INVOKE_LAMBDAS_DIALOG";
export const OrganizationDialogType = "ORGANIZATION_DIALOG";
export const UserDialogType = "USER_DIALOG";
export const UserFormDialogType = "USER_FORM_DIALOG";

const MODAL_COMPONENTS = {
  [InvokeLambdasDialogType]: InvokeLambdasDialog,
  [OrganizationDialogType]: OrganizationDialog,
  [UserDialogType]: UserDialog,
  [UserFormDialogType]: UserFormDialog,
};

class ModalRoot extends React.Component {
  constructor(props) {
    super(props);
    this.escFunction = this.escFunction.bind(this);
  }
  escFunction(event) {
    const { modalProps } = this.props;

    if (event.keyCode === 27) {
      if (modalProps && modalProps.closeOnESC) {
        this.props.hide();
      }
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    const { modalType, modalProps } = this.props;

    if (!modalType) {
      return null;
    }

    const SpecificModal = MODAL_COMPONENTS[modalType];
    return SpecificModal ? <SpecificModal {...modalProps} /> : null;
  }
}

export default connect(
  (state) => state.modal,
  (dispatch) => ({
    hide: () => dispatch(hideModal()),
  })
)(ModalRoot);
