import "whatwg-fetch";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { Router, hashHistory } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./store";

// @ts-ignore
import routes from "./routes";

import "react-widgets/dist/css/react-widgets.css";

import "../styles/main.less";
import "react-redux-toastr/src/styles/index.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { ConfirmationProvider } from "./providers/ConfirmationProvider";
import { ConfigProvider, ConfigProps } from "./providers/ConfigProvider";

const history = syncHistoryWithStore(hashHistory, store);

fetch("/config.json")
  .then((res): Promise<ConfigProps> => {
    if (res.status === 200) {
      return res.json();
    } else {
      return Promise.resolve({
        BE_API_HOST: process.env.BE_API_HOST || "https://search-api.sien.com",
      });
    }
  })
  .then((config) => {
    return ReactDOM.render(
      <ConfigProvider {...config}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConfirmationProvider>
              <Router history={history} routes={routes} />
            </ConfirmationProvider>
          </PersistGate>
        </Provider>
      </ConfigProvider>,
      document.getElementById("app")
    );
  });
