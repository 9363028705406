export const SELECT_APP_MODE_AND_ORGANIZATION = "SELECT_APP_MODE_AND_ORGANIZATION";
export const setOrganizationId = (organizationId: string | null) => {
  return {
    type: SELECT_APP_MODE_AND_ORGANIZATION,
    mode: "pb",
    organizationId,
  };
};

export const SET_CURRENT_LOCATION_CONFIG = "SET_CURRENT_LOCATION_CONFIG";
export const setCurrentLocationConfig = (url: string, resource: any) => {
  return {
    type: SET_CURRENT_LOCATION_CONFIG,
    url,
    resource,
  };
};

export const SET_CURRENT_LOCATION_PATHNAME = "SET_CURRENT_LOCATION_PATHNAME";
export const setCurrentLocationPathname = (pathname: string) => {
  return {
    type: SET_CURRENT_LOCATION_PATHNAME,
    pathname,
  };
};
