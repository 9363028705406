import { ValidateDomain, ValidateUrl } from "../../../utils/validation";
import React from "react";
import { isInteger, isNaN } from "lodash";

import moment from "moment";
import { parseISO } from "date-fns";

const invalidDomainErr = (
  <span>
    Please use just the domain part of the url: for example if your url is
    http://www.google.com/search, the domain would be <strong>google.com</strong>
  </span>
);

export const isNullOrEmpty = (str: string | null) => {
  if (!str) {
    return true;
  }

  if (typeof str === "string") {
    return str.trim().length === 0;
  }

  return false;
};

const isNullOrUndefined = (v?: any) =>
  v === null || v === undefined || (typeof v === "string" && v.trim() === "");

export const required = (v: string) => (isNullOrUndefined(v) ? "Required" : undefined);
export const minLength = (minLength: number) => (str: string) =>
  !isNullOrUndefined(str) && (str + "").trim().length < minLength
    ? `Must be at least ${minLength} character(s) long`
    : undefined;
export const maxLength = (maxLength: number) => (str: string) =>
  !isNullOrUndefined(str) && (str + "").trim().length > maxLength
    ? `Must not exceed ${maxLength} character(s)`
    : undefined;

export const isValidJSON = (str: string) => {
  try {
    const parsed = JSON.parse(str);

    if (parsed instanceof Object) {
      return undefined;
    } else {
      return "Invalid JSON";
    }
  } catch {
    return "Invalid JSON";
  }
};

export const isNumeric = (value: string | null | undefined) =>
  isNaN(Number(value)) ? "Invalid value" : undefined;

export const isValidInteger = (value: string | null | undefined) =>
  !isNullOrUndefined(value) && isInteger(Number(value)) == false ? "Invalid value" : undefined;

export const isInRange = (a: number, b: number) => (value: string | null | undefined) => {
  if (isNumeric(value)) return isNumeric(value);

  const number = Number(value);

  if (number >= a && number <= b) return undefined;

  return `Must be from ${a} to ${b}`;
};

export const isGreaterThan = (gt: number) => (i: number) =>
  !isNullOrUndefined(i) && i <= gt ? `Must be greater than ${gt}` : undefined;

export const isGreaterThanOrEqual = (gt: number, msg: string) => (i: number) =>
  !isNullOrUndefined(i) && i < gt ? msg || `Must be greater than ${gt}` : undefined;

export const isValidDate = (date: any) => {
  if (isNullOrUndefined(date)) return undefined;

  let d = moment(date);
  return d.isValid() == false ? "Invalid date" : undefined;
};
export const isValidDomain = (str: string) =>
  !isNullOrUndefined(str) && ValidateDomain(str) == false ? invalidDomainErr : undefined;

export const isValidUrl = (str: string) =>
  !isNullOrUndefined(str) && ValidateUrl(str) == false ? "Invalid url" : undefined;

export const hasItems = (a: any) =>
  !isNullOrUndefined(a) && a instanceof Array && a.length == 0 ? "Required" : undefined;

export const validateQueryTargetingItem = (value: any, values: any) => {
  if (typeof value == "undefined") {
    return undefined;
  }

  const keySplit = typeof value.key == "string" ? value.key.split(":", 2) : [];
  const param = keySplit[1] || "";

  if (isNullOrEmpty(param)) {
    return "Required";
  }

  if (!param.match(/^[a-z0-9-_]+$/i)) {
    return "Invalid parameter name (only a-z0-9-_ allowed)";
  }

  if (values.queryFilterRules.filter((e: any) => e.key == value.key).length > 1) {
    return "Duplicate parameter name";
  }

  let val = (value.value + "").trim();

  if (val.length == 0) {
    return "Value is required";
  }

  return undefined;
};

export const validateCreativeImageUrl = (value: any) => {
  if (value && value.files && value.files.length > 0) {
    return;
  }

  if (isNullOrEmpty(value.url)) {
    return "Required";
  } else if (!ValidateUrl(value.url)) {
    return "The image url is not valid";
  }
};

export const validateLogoUrl = (value: any) => {
  if (!value) {
    return;
  }

  if (value && value.files && value.files.length > 0) {
    return;
  }

  if (isNullOrEmpty(value.url)) {
    return;
  } else if (!ValidateUrl(value.url)) {
    return "The image url is not valid";
  }
};

export const validateConversionKey = (value: string) => {
  if (isNullOrEmpty(value)) {
    return;
  }

  if (!value.match(/^[a-z0-9-_]+$/i)) {
    return "Invalid key (only a-z0-9-_ allowed)";
  }
};

export const isLaterThanNow = (value: string) => {
  if (parseISO(value) < new Date(Date.now())) {
    return "Time in past is not valid";
  }
};

export default (values: any) => {
  if (!values) return;

  let errs = {};

  return errs;
};
