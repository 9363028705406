export const getCurrentUserState = (state) => {
  const { users = null } = state;
  if (!users) return null;

  return users.current || null;
};

export const getCurrentUserData = (state) => {
  const current = getCurrentUserState(state);
  return current ? current.data || null : null;
};

export const getCurrentUserFetchStatus = (state) => {
  const current = getCurrentUserState(state);
  const { isFetching, success } = current || {};
  return { isFetching, success };
};

export const getUserItemsState = (state) => {
  const { users = null } = state;
  if (!users) return null;

  return users.items || null;
};

export const getUserItemsSearchSettingsState = (state) => {
  const { users = null } = state;
  if (!users) return null;

  return users.settings || null;
};

export const getUserItemsData = (state) => {
  const items = getUserItemsState(state);
  return items ? items.data || null : null;
};

export const getUserItemsFetchStatus = (state) => {
  const items = getUserItemsState(state);
  const { isFetching, success } = items || {};
  return { isFetching, success };
};

export const getUserItemsSearchSettings = (state) => {
  const settings = getUserItemsSearchSettingsState(state);
  const { offset, limit, search, sort, order } = settings || {};
  return { offset, limit, search, sort, order };
};
