import React, { useCallback } from "react";
import { Button, Col, ControlLabel, FormGroup, Glyphicon } from "react-bootstrap";
import { Field, WrappedFieldArrayProps } from "redux-form";

// @ts-ignore
import FormTextRowField from "../../../components/FormTextRowField.react";
import { isInRange, isNumeric, isValidInteger, required } from "../../campaign/validation/validate";

interface Props extends WrappedFieldArrayProps {
  disabled?: boolean;
}

export const renderTiers = ({ fields, meta, disabled }: Props) => {
  const addTier = () => {
    const lastIndex = fields.length - 1;
    const prevLimit = lastIndex > 0 ? Number(fields.get(lastIndex - 1).limit) : 999;
    const currentRevshare = lastIndex >= 0 ? Number(fields.get(lastIndex).revshare) : 70;

    fields.remove(lastIndex);

    fields.push({
      revshare: currentRevshare.toFixed(2),
      limit: String(prevLimit + 1),
    });

    fields.push({
      revshare: (currentRevshare + 1).toFixed(2),
      limit: "-1",
    });
  };

  const removeTier = (index: number) => {
    if (index === fields.length - 1) {
      const revshare = fields.get(index - 1).revshare;
      fields.remove(index);
      fields.remove(index - 1);
      fields.push({ revshare, limit: "-1" });
    } else {
      fields.remove(index);
    }
  };

  return (
    <>
      <FormGroup>
        <Col componentClass={ControlLabel} mdOffset={3} md={2}>
          Revshare
        </Col>
        <Col componentClass={ControlLabel} md={3}>
          Limit
        </Col>
      </FormGroup>
      {fields.map((tier, index) => (
        <FormGroup key={`tier-${index}`}>
          <Col componentClass={ControlLabel} md={3}>
            tier{index}
          </Col>

          <Col md={2}>
            <Field
              controlStyle={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              name={`${tier}.revshare`}
              component={FormTextRowField}
              validate={[required, isNumeric, isInRange(0, 100)]}
              disabled={disabled}
            />
          </Col>
          <Col md={4}>
            <Field
              controlStyle={{ xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }}
              rightLabelStyle={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
              controlStyles={{}}
              rightLabelStyles={{}}
              name={`${tier}.limit`}
              component={FormTextRowField}
              validate={[
                required,
                isValidInteger,
                (value?: string) => (Number(value) === 0 ? "Limit cannot be 0" : undefined),
              ]}
              rightLabelIcon={fields.length > 2 && !disabled ? "fa fa-trash" : ""}
              onClick={() => removeTier(index)}
              disabled={disabled}
            />
          </Col>
        </FormGroup>
      ))}
      <FormGroup>
        {meta.error && (
          <Col mdOffset={3} md={9}>
            <p className="text-danger">{meta.error}</p>
          </Col>
        )}
        <Col mdOffset={3} md={9}>
          <Button bsSize="small" onClick={addTier} disabled={disabled}>
            <Glyphicon glyph="plus" /> Add tier
          </Button>
        </Col>
      </FormGroup>
    </>
  );
};
