// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RlnPRORATK7yejpRaGCn{max-width:500px}`, "",{"version":3,"sources":["webpack://./client/app/components/Confirmation/Confirmation.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA","sourcesContent":[".dialog {\n  max-width: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": `RlnPRORATK7yejpRaGCn`
};
export default ___CSS_LOADER_EXPORT___;
