import React from "react";
import PropTypes from "prop-types";
import { flowRight } from "lodash";
import { withRouter } from "react-router";

import { Dropdown, MenuItem } from "react-bootstrap";
import CustomToggle from "./CustomToggle";
import { logout } from "../../actions/auth";
import {
  getInitialUser,
  getSuperadminStatus,
  getManagementStatus,
  getUser,
} from "../../selectors/app";
import connect from "react-redux/es/connect/connect";
import { hideModal, showModal } from "../../actions/modals";
import { InvokeLambdasDialogType } from "../modals";
import { axiosInstance } from "../../api/axiosInstance";
import { showSuccessNotification } from "../../utils/notifications";

class UserDropdown extends React.Component {
  getUrl = (url) => {
    const { user, userOrganizations, selectedOrganizationId } = this.props;

    const originalPartnerCode = user?.groupPrincipal ? userOrganizations[0]?.partnerCode : null;

    const urlWithQuery = originalPartnerCode
      ? `${url}?partnerCodes=${originalPartnerCode}`
      : `${url}`;

    return urlWithQuery.replace(":distributionpartnercodes", selectedOrganizationId);
  };

  onSelect = (eventKey) => {
    const {
      router: { push },
    } = this.props;

    switch (eventKey) {
      case "logout":
        this.props.logout();
        return;
      case "admin":
        push("/admin/organizations");
        return;
      case "reporting":
        push("/");
        return;
      case "switch":
        push("/switchto/initial");
        return;
      case "generate_finance_report":
        alert(
          "The download URL of the monthly report will be emailed to you in maximum 15 minutes. If that does not happen retry later as the MSFT backend can be slow sometimes."
        );
        axiosInstance.get("/fetch-finance-report");
        return;
      case "compliance":
        push("/compliance/jailing_and_blocking");
        return;
      case "account":
        push(this.getUrl("/:distributionpartnercodes/account/api_access"));
        return;
      case "invoke_lambdas":
        this.props.showInvokeLambdasDialog({
          closeOnESC: true,
          onSubmit: (submitData) => {
            axiosInstance.post("/invokelambda", submitData);
            showSuccessNotification("Lambda invoke successfull");

            this.props.hideModal();
          },
          onCancel: () => {
            this.props.hideModal();
          },
        });
      default:
        return;
    }
  };

  render() {
    const {
      user,
      superAdmin,
      management,
      initialUser,
      location: { pathname },
    } = this.props;

    const isAdminPage = pathname.includes("admin");
    const isCompliancePage = pathname.includes("compliance");
    const isAccountPage = pathname.includes("account");
    const isReporting = !isAdminPage && !isCompliancePage && !isAccountPage;

    const isStartOfMonth = new Date().getDate() === 1;

    const isGenerateDisabled = isStartOfMonth ? "grayout" : "";
    return (
      <li className="">
        <Dropdown id="dropdown-user-profile-menu" onSelect={this.onSelect}>
          <CustomToggle bsRole="toggle" display={true}>
            <i className="fa fa-user-o fa-2" aria-hidden="true" />
            <span style={{ margin: "0 5px" }}>{user.name}</span>
            <span className="fa fa-angle-down" />
          </CustomToggle>

          <Dropdown.Menu className="dropdown-usermenu pull-right">
            {initialUser && <MenuItem eventKey="switch">Switch to {initialUser.name}</MenuItem>}
            {!isReporting && <MenuItem eventKey="reporting">Webapp Reporting</MenuItem>}
            {superAdmin && !isAdminPage && <MenuItem eventKey="admin">Webapp Admin</MenuItem>}
            {superAdmin && !isCompliancePage && (
              <MenuItem eventKey="compliance">Compliance & Quality</MenuItem>
            )}
            {!isAccountPage && <MenuItem eventKey="account">Account</MenuItem>}
            {superAdmin && (
              <MenuItem
                disabled={!!isGenerateDisabled}
                className={isGenerateDisabled}
                eventKey="generate_finance_report"
              >
                Download Monthly report
              </MenuItem>
            )}
            {management && <MenuItem eventKey="invoke_lambdas">Reprocess data</MenuItem>}
            <MenuItem eventKey="logout">
              <i className="fa fa-sign-out pull-right" />
              Log Out
            </MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </li>
    );
  }
}

UserDropdown.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    initialUser: getInitialUser(state),
    user: getUser(state) || { name: "N/A" },
    selectedOrganizationId: state.app.distributionpartnercodes,
    userOrganizations: state.auth?.organizations,
    superAdmin: getSuperadminStatus(state),
    management: getManagementStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    hideModal: () => dispatch(hideModal()),
    showInvokeLambdasDialog: (params) => dispatch(showModal(InvokeLambdasDialogType, params)),
  };
};

export default flowRight([connect(mapStateToProps, mapDispatchToProps), withRouter])(UserDropdown);
