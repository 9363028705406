import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ActiveFilters } from "../../../types/Filters";
import { isActiveFiltersEqual } from "../../../utils/object";
import { DataSource } from "../../../types/DataSource";
import { getDefaultMetrics } from "../../../utils/metrics";
import { getDefaultDimensions } from "../../../utils/dimensions";
import { LOGOUT } from "../../../actions/auth";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";

interface Reporting {
  progress: number;
  status: QueryStatus;
  data: any[];
  currentParams: ActiveFilters | null;
  errorMessage: string | null;
}

const initialState: Reporting = {
  progress: 0,
  status: QueryStatus.uninitialized,
  data: [],
  currentParams: null,
  errorMessage: null,
};

export const reportSlice = createSlice({
  name: "reportingSlice",
  initialState,
  reducers: {
    startFetching: (state, action: PayloadAction<ActiveFilters>) => {
      state.status = QueryStatus.pending;
      state.currentParams = action.payload;
      state.errorMessage = null;
      state.progress = 0;
    },
    completeFetching: (state, action: PayloadAction<{ data: any[]; params: ActiveFilters }>) => {
      if (state.currentParams && isActiveFiltersEqual(state.currentParams, action.payload.params)) {
        state.status = QueryStatus.fulfilled;
        state.data = action.payload.data;
      }
    },
    errorFetching: (state, action: PayloadAction<string>) => {
      state.data = [];
      state.status = QueryStatus.rejected;
      state.errorMessage = action.payload;
    },
    updateProgress: (state, action: PayloadAction<{ progress: number; params: ActiveFilters }>) => {
      if (state.currentParams && isActiveFiltersEqual(state.currentParams, action.payload.params))
        state.progress = action.payload.progress;
    },
  },
});

export interface Columns {
  metrics: string[];
  dimensions: string[];
}

export type ReportingSettings = Record<DataSource, Columns>;

export const reportingSettingsInitalState: ReportingSettings = {
  mainreporting: {
    metrics: getDefaultMetrics("mainreporting"),
    dimensions: getDefaultDimensions("mainreporting"),
  },
  mainreportingv2: {
    metrics: getDefaultMetrics("mainreportingv2"),
    dimensions: getDefaultDimensions("mainreportingv2"),
  },
  ptagreporting: {
    metrics: getDefaultMetrics("ptagreporting"),
    dimensions: getDefaultDimensions("ptagreporting"),
  },
  ptagreportingv2: {
    metrics: getDefaultMetrics("ptagreportingv2"),
    dimensions: getDefaultDimensions("ptagreportingv2"),
  },
  ptqsreporting: {
    metrics: getDefaultMetrics("ptqsreporting"),
    dimensions: getDefaultDimensions("ptqsreporting"),
  },
  ptqsPerPartnerReporting: {
    metrics: getDefaultMetrics("ptqsPerPartnerReporting"),
    dimensions: getDefaultDimensions("ptqsPerPartnerReporting"),
  },
  referral: {
    metrics: getDefaultMetrics("referral"),
    dimensions: getDefaultDimensions("referral"),
  },
  invoicing: {
    metrics: getDefaultMetrics("invoicing"),
    dimensions: getDefaultDimensions("invoicing"),
  },
};

export const reportingSettingsSlice = createSlice({
  name: "reportingSettingsSlice",
  initialState: reportingSettingsInitalState,
  reducers: {
    update: (state, action: PayloadAction<{ datasource: DataSource; columns: Columns }>) => {
      state[action.payload.datasource] = action.payload.columns;
    },
  },
  extraReducers: {
    [LOGOUT]: () => reportingSettingsInitalState,
  },
});
