export const getCurrentOrganizationState = (state) => {
  const { organizations = null } = state;
  if (!organizations) return null;

  return organizations.current || null;
};

export const getCurrentOrganizationData = (state) => {
  const current = getCurrentOrganizationState(state);
  return current ? current.data || null : null;
};

export const getCurrentOrganizationFetchStatus = (state) => {
  const current = getCurrentOrganizationState(state);
  const { isFetching, success } = current || {};
  return { isFetching, success };
};

export const getOrganizationItemsState = (state) => {
  const { organizations = null } = state;
  if (!organizations) return null;

  return organizations.items || null;
};

export const getOrganizationItemsSearchSettingsState = (state) => {
  const { organizations = null } = state;
  if (!organizations) return null;

  return organizations.settings || null;
};

export const getOrganizationItemsData = (state) => {
  const items = getOrganizationItemsState(state);
  return items ? items.data || null : null;
};

export const getOrganizationItemsFetchStatus = (state) => {
  const items = getOrganizationItemsState(state);
  const { isFetching, success } = items || {};
  return { isFetching, success };
};

export const getOrganizationItemsSearchSettings = (state) => {
  const settings = getOrganizationItemsSearchSettingsState(state);
  const { offset, limit, search, sort, order } = settings || {};
  return { offset, limit, search, sort, order };
};
