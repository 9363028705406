import { DataSource } from "../types/DataSource";

const mainreportingDimensions = [
  "date",
  "partner_code",
  "form_code",
  "market",
  "device_type",
  "website_country",
  "language",
  "user_country",
  //"websitelanguage","currency" - extra fields
];

const ptagreportingDimensions = [
  "date",
  "partner_code",
  "form_code",
  "market",
  "device_type",
  "website_country",
  "browser_name",
  "partner_tag",
];

const mainreportingV2Dimensions = [
  "date",
  "partner_code",
  "form_code",
  "country",
  "browser_name",
  "ad_country",
  "follow_on_type",
  // "user_country", "device_type", "ad_language" - extra fields
];

const ptagreportingV2Dimensions = [
  "date",
  "partner_code",
  "partner_tag",
  "form_code",
  "country",
  "ad_country",
  "follow_on_type",
  "browser_name",
  // "user_country", "device_type", "ad_language" - extra fields
];

const ptqsreportingDimensions = [
  "date",
  "partner_code",
  "partner_tag",
  "ad_country",
  "device_type",
];

const ptqsPerPartnerDimensions = ["date", "partner_code", "ad_country", "device_type"];

export const dimensionNames: Record<string, string> = {
  date: "Date",
  hour: "Hour",
  partner_code: "PartnerCode",
  form_code: "FormCode",
  market: "Market",
  device_type: "DeviceType",
  website_country: "WebSiteCountry",
  language: "Language",
  user_country: "UserCountry",
  browser_name: "Browser Name",
  partner_tag: "PartnerTag",
  country: "Country",
  ad_country: "AdCountry",
  follow_on_type: "FollowOnType",
};

export const getAllDimensions = (dataSource: DataSource): string[] => {
  switch (dataSource) {
    case "mainreporting":
      return mainreportingDimensions;
    case "mainreportingv2":
      return mainreportingV2Dimensions;
    case "ptagreporting":
      return ptagreportingDimensions;
    case "ptagreportingv2":
      return ptagreportingV2Dimensions;
    case "ptqsreporting":
      return ptqsreportingDimensions;
    case "ptqsPerPartnerReporting":
      return ptqsPerPartnerDimensions;
    default:
      return [];
  }
};

export const getDefaultDimensions = (dataSource: DataSource): string[] => {
  switch (dataSource) {
    case "mainreporting":
    case "mainreportingv2":
      return ["date", "partner_code", "form_code"];
    case "ptagreporting":
    case "ptagreportingv2":
      return ["date", "partner_code", "partner_tag", "form_code"];
    default:
      return getAllDimensions(dataSource);
  }
};
