import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, destroy } from "redux-form";
import { Form, Button, ButtonToolbar, Row, Col, Modal } from "react-bootstrap";

import { Loader } from "../../../components/Loader";

import { fetchUser } from "../../../actions/users";

import { NEW_USER_FORM_NAME } from "../constants";

import { UserFields } from "./UserFields";

import { getCurrentUserData, getCurrentUserFetchStatus } from "../../../selectors/users";
import { getManagementStatus } from "../../../selectors/app";

import { axiosInstance } from "../../../api/axiosInstance";
import { showErrorNotification, showSuccessNotification } from "../../../utils/notifications";

class UserFormDialog extends Component {
  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
    this.submitInProgress = false;
  }

  isUpdate() {
    return !!this.props.id;
  }

  fetchSuccessful(oldProps, nextProps) {
    return oldProps.isFetching && !nextProps.isFetching && nextProps.success;
  }

  fetchFailed(oldProps, nextProps) {
    return oldProps.isFetching && !nextProps.isFetching && !nextProps.success;
  }

  componentDidMount() {
    const { loadUser } = this.props;
    this.isUpdate() ? loadUser() : this.props.initialize({});
  }

  componentWillUnmount() {
    const { destroyForm } = this.props;
    destroyForm(NEW_USER_FORM_NAME);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.fetchSuccessful(this.props, nextProps)) {
      if (this.isUpdate()) {
        const nextUser = { ...(nextProps.user || {}) };

        this.props.initialize({
          id: nextUser.id,
          login: nextUser.email,
          name: nextUser.name,
        });
      }
    }
  }

  onSubmit = async (values) => {
    Object.keys(values)
      .filter((key) => typeof values[key] === "string")
      .forEach((key) => (values[key] = values[key].trim()));

    try {
      const promise = this.isUpdate()
        ? axiosInstance.put(`/admin/users/${values.id}/update-password`, values)
        : axiosInstance.post("/admin/add-user", values);

      const { data } = await promise;

      showSuccessNotification("Password changed");

      this.props.onSubmit(data);
    } catch (error) {
      showErrorNotification(
        "Error saving password",
        "Please check that the form is filled in correctly and try again"
      );
    }
  };

  render() {
    const { isFetching, submitting, handleSubmit, onCancel, isLoading, management } = this.props;

    return (
      <Modal show animation={false} bsSize="large">
        <Form horizontal onSubmit={handleSubmit(this.onSubmit)} autoComplete="new-password">
          <Modal.Body>
            <Row>
              <div className="x_title">
                <h2>{this.isUpdate() ? "Update user password" : "Add user"}</h2>
                <div className="clearfix" />
              </div>
              {isFetching || submitting ? <Loader /> : <UserFields isUpdate={this.isUpdate()} />}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col xs={12}>
              <ButtonToolbar className="pull-right">
                <Button
                  type="submit"
                  bsStyle="success"
                  disabled={isLoading || !management || submitting}
                >
                  {this.isUpdate() ? "Update" : "Create"}
                </Button>
                <Button onClick={() => onCancel()} bsStyle="danger">
                  Cancel
                </Button>
              </ButtonToolbar>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

UserFormDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  let { isFetching, success } = getCurrentUserFetchStatus(state);
  const management = getManagementStatus(state);
  let user = getCurrentUserData(state);
  if (user && user.userData) user = user.userData;

  return {
    isFetching,
    success,
    user,
    management,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let { id } = ownProps;

  return {
    loadUser: () => dispatch(fetchUser(id)),
    destroyForm: (form) => dispatch(destroy(form)),
  };
};

UserFormDialog = connect(mapStateToProps, mapDispatchToProps)(UserFormDialog);

UserFormDialog = reduxForm({
  form: NEW_USER_FORM_NAME,
  destroyOnUnmount: false,
})(UserFormDialog);

export { UserFormDialog };
