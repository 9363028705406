import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  reduxForm,
  Field,
  FieldArray,
  initialize,
  SubmissionError,
  destroy,
  formValueSelector,
  isDirty,
  change,
} from "redux-form";
import {
  Form,
  Button,
  ButtonToolbar,
  Row,
  Col,
  Modal,
  Glyphicon,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import FormTextRowField from "../../../components/FormTextRowField.react";
import FormCheckboxField from "../../../components/FormCheckboxField.react";
import { FormSelectField } from "../../../components/FormSelectField";

import { renderTiers } from "./renderTiers";

import { renderFormCodes } from "./renderFormCodes";

import { axiosInstance } from "../../../api/axiosInstance";

import {
  DEFAULT_TIERS,
  FORM_NAME,
  INITIAL_ORGANIZATION_FORM_VALUES,
  ORGANIZATIONS_RESOURCES,
} from "../constants";

// TODO: move validators to common path
import { required, minLength, maxLength } from "../../campaign/validation/validate";

import { getCurrentOrganizationFetchStatus } from "../../../selectors/organizations";
import { getManagementStatus } from "../../../selectors/app";

import { Loader } from "../../../components/Loader";
import { differenceBy, differenceWith, intersectionBy, isEmpty, isEqual, startCase } from "lodash";
import {
  getTiersData,
  getBackendTiers,
  validateUniqEmail,
  validateTiers,
  TIERS_TYPES,
  TIERS_TYPE_NAME_MAPPING,
} from "./helpers";
import { validateEmail } from "../../../utils/validation";
import { showErrorNotification, showSuccessNotification } from "../../../utils/notifications";
import { organizationsApi } from "../../../api/organizationsApi";
import { pushOrganization } from "../../../actions/organizations";

const OTHER_FORM_CODE_DATA = {
  id: "0",
  formCode: "Other",
  productName: "Other",
  url: "All products",
  extensionUrl: "null",
};

const normalizeFields = (organizationData) => {
  Object.keys(ORGANIZATIONS_RESOURCES).forEach((resource) => {
    const resourceName = ORGANIZATIONS_RESOURCES[resource];

    if (organizationData.resources.includes(resource)) {
      organizationData[resourceName] = true;
    } else {
      organizationData[resourceName] = false;
    }
  });

  const tiersData = getTiersData(organizationData);

  return {
    ...organizationData,
    ...tiersData,
  };
};

class OrganizationDialog extends Component {
  constructor() {
    super();

    this.state = {
      controlsDisabled: false,
      displayFullRevShare: false,
      currentOrganization: INITIAL_ORGANIZATION_FORM_VALUES,
      isFetching: false,
      isLoading: false,
    };
  }

  handleOnLinkClick = (e) => {
    e.preventDefault();
    const { displayFullRevShare } = this.state;

    this.setState({
      displayFullRevShare: !displayFullRevShare,
    });
  };

  handleControlsChange = () => {
    const { controlsDisabled } = this.state;
    this.setState({
      controlsDisabled: !controlsDisabled,
    });
  };

  isUpdate() {
    return !!this.props.id;
  }

  fetchOrganization = async (organizationId) => {
    const [
      { data: organization },
      {
        data: { hasCredentials, enabled },
      },
    ] = await Promise.all([
      axiosInstance.get(`/admin/organizations/${organizationId}`),
      axiosInstance.get(`/organization/api-access/${organizationId}`),
    ]);

    return {
      ...organization.data.organizationData,
      hasCredentials,
      isApiAccessEnabled: enabled,
    };
  };

  fetchFormcodes = async (organizationId) => {
    try {
      const { data } = await axiosInstance.get(`/admin/formcodes/${organizationId}`);

      return data;
    } catch {
      return [];
    }
  };

  loadData = async () => {
    const { _id, initializeForm } = this.props;

    if (Boolean(_id)) {
      this.setState({ isFetching: true });
      const [organization, formCodes] = await Promise.all([
        this.fetchOrganization(_id),
        this.fetchFormcodes(_id),
      ]);

      const currentOrganization = { ...organization, formCodes };

      this.setState({ isFetching: false, currentOrganization });

      const initalValues = normalizeFields(currentOrganization);

      initializeForm(initalValues);

      this.setState({
        controlsDisabled: !initalValues.enabled,
      });
    } else {
      initializeForm(INITIAL_ORGANIZATION_FORM_VALUES);
      this.setState({ currentOrganization: INITIAL_ORGANIZATION_FORM_VALUES });
    }
  };

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    const { destroyForm } = this.props;
    destroyForm(FORM_NAME);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Get tiers from principal partner
    if (
      nextProps.principalPartner &&
      nextProps.cumulativeVolumes &&
      (!this.props.principalPartner || !this.props.cumulativeVolumes)
    ) {
      const { tiers_type, revshare_tiers, gross_rev_tiers, search_count_tiers } = getTiersData(
        nextProps.principalPartner
      );

      this.prevTiersData = {
        tiers_type: nextProps.tiers_type,
        revshare_tiers: nextProps.revshare_tiers,
        gross_rev_tiers: nextProps.gross_rev_tiers,
        search_count_tiers: nextProps.search_count_tiers,
      };

      this.props.changeValue("tiers_type", tiers_type);
      this.props.changeValue("revshare_tiers", revshare_tiers);
      this.props.changeValue("gross_rev_tiers", gross_rev_tiers);
      this.props.changeValue("search_count_tiers", search_count_tiers);
    }

    // Restore prev tiers data
    if (
      (!nextProps.principalPartner || !nextProps.cumulativeVolumes) &&
      this.props.principalPartner &&
      this.props.cumulativeVolumes
    ) {
      const { tiers_type, revshare_tiers, gross_rev_tiers, search_count_tiers } =
        this.prevTiersData || {};
      this.props.changeValue("tiers_type", tiers_type || "revshare");
      this.props.changeValue("revshare_tiers", revshare_tiers || DEFAULT_TIERS);
      this.props.changeValue("gross_rev_tiers", gross_rev_tiers || DEFAULT_TIERS);
      this.props.changeValue("search_count_tiers", search_count_tiers || DEFAULT_TIERS);
    }
  }

  submitOrganization = async (organization) => {
    const formData = Object.assign({}, organization);

    Object.keys(formData).forEach((property) => {
      if (
        isEmpty(formData[property]) &&
        typeof formData[property] !== "boolean" &&
        typeof formData[property] !== "number"
      ) {
        delete formData[property];
      }
    });

    const tiers = getBackendTiers(
      this.props.principalPartner && this.props.cumulativeVolumes ? this.prevTiersData : formData
    );

    formData.revshare_tiers = tiers.revshare_tiers;
    formData.gross_rev_tiers = tiers.gross_rev_tiers;
    formData.search_count_tiers = tiers.search_count_tiers;

    delete formData.tiers_type;

    formData.filtering_threshold = parseInt(formData.filtering_threshold).toString();

    Object.keys(formData)
      .filter((key) => typeof formData[key] === "string")
      .forEach((key) => (formData[key] = formData[key].trim()));

    const { data } = this.isUpdate()
      ? await axiosInstance.put(`/admin/organizations/${formData._id}`, formData)
      : await axiosInstance.post("/admin/add-organization", formData);

    if (!data) {
      throw new SubmissionError({
        _error: "There was a network error, please try again later.",
      });
    } else if (data.status === 4) {
      throw new SubmissionError({
        domain:
          "Organization URN already exists. You cannot have multiple organizations with the same URN",
      });
    } else if (data.status === 1 && data.errors && typeof data.errors === "object") {
      let errs = {};

      Object.keys(data.errors).forEach((key) => (errs[key] = data.errors[key].join("\n")));

      throw new SubmissionError(errs);
    }

    const {
      _id,
      id,
      name,
      distributionpartnercode,
      urn,
      resources,
      enabled,
      tempest_coverage,
      ...rest
    } = data.data.organizationData;

    return {
      id: _id,
      name,
      partnerCode: distributionpartnercode,
      urn,
      resources,
      enabled,
      tempestCoverage: tempest_coverage,
      ...rest,
    };
  };

  getFormCodes = (formCodes) => {
    const { currentOrganization } = this.state;
    const normalizedFormCodes = formCodes.map(
      ({ id, formCode, productName, url, extensionUrl }) => ({
        id,
        formCode: formCode.trim(),
        productName: productName.trim(),
        url: url.trim(),
        extensionUrl: extensionUrl?.trim() || "",
      })
    );

    if (!currentOrganization._id) {
      return {
        formCodesToUpdate: [],
        formCodesToCreate: [OTHER_FORM_CODE_DATA, ...normalizedFormCodes],
        formCodesToDelete: [],
      };
    }

    const nextFormcodes = differenceWith(
      currentOrganization.formCodes,
      normalizedFormCodes,
      isEqual
    );

    return {
      formCodesToUpdate: intersectionBy(normalizedFormCodes, nextFormcodes, "id"),
      formCodesToCreate: differenceBy(normalizedFormCodes, currentOrganization.formCodes, "id"),
      formCodesToDelete: differenceBy(currentOrganization.formCodes, normalizedFormCodes, "id"),
    };
  };

  submitFormCodes = async (formCodes, organizationId) => {
    const { formCodesToUpdate, formCodesToCreate, formCodesToDelete } =
      this.getFormCodes(formCodes);

    const updatePromises = formCodesToUpdate.map(({ id, ...data }) =>
      axiosInstance.put(`/admin/formcodes/${id}`, { _id: id, ...data })
    );

    const createPromises = formCodesToCreate.map(({ id, ...data }) =>
      axiosInstance.post(`/admin/add-formcode`, { ...data, organizationId: organizationId })
    );

    const deletePromises = formCodesToDelete.map((formCode) =>
      axiosInstance.delete(`/admin/formcodes/${formCode.id}`)
    );

    return Promise.all([...updatePromises, ...createPromises, ...deletePromises]);
  };

  submitApiAccess = async (organizationId, enabled) => {
    const { isApiAccessEnabled } = this.state.currentOrganization;
    if (isApiAccessEnabled !== enabled) {
      return axiosInstance.post(`/organization/api-access/${enabled ? "enable" : "disable"}`, {
        organizationId,
      });
    }
  };

  onSubmit = async (data) => {
    const { formCodes, isApiAccessEnabled, ...restData } = data;

    this.setState({ isLoading: true });

    try {
      const organizationData = await this.submitOrganization(restData);

      await this.submitFormCodes(formCodes, organizationData.id);
      await this.submitApiAccess(organizationData.id, isApiAccessEnabled);

      this.props.updateOrganizationsList(organizationData);

      this.props.onSubmit(organizationData);

      showSuccessNotification("Organization saved");
    } catch (error) {
      showErrorNotification(
        "Error saving organization",
        "Please check that the form is filled in correctly and try again"
      );
    } finally {
      this.setState({ isLoading: false });
    }
  };

  renderEmails = ({ fields }) => {
    const defaultEmail = this.state.currentOrganization.user_emails?.[0];

    return (
      <>
        <Col md={9} mdOffset={3}>
          <h4>Emails to send filtering alerts</h4>
        </Col>
        {defaultEmail && (
          <FormTextRowField
            type="text"
            label="Default Email"
            labelStyle={{ xs: 12, md: 2, sm: 2 }}
            controlStyle={{ xs: 12, md: 9, sm: 9 }}
            input={{ value: defaultEmail }}
            meta={{}}
            disabled
          />
        )}
        {fields.map((email, index) => (
          <Field
            key={`email-${index}`}
            name={email}
            type="text"
            component={FormTextRowField}
            label={`Email #${index + 1}`}
            placeholder="email@example.com"
            labelStyle={{ xs: 12, md: 2, sm: 2 }}
            controlStyle={{ xs: 12, md: 9, sm: 9 }}
            rightLabelIcon="fa fa-trash"
            onClick={() => fields.remove(index)}
            validate={[required, validateEmail]}
          />
        ))}
        <Col md={9} mdOffset={3}>
          <ButtonToolbar style={{ marginBottom: 20 }}>
            <Button bsSize="small" bsStyle="default" onClick={() => fields.push("")}>
              <i className="fa fa-plus" /> Add Email
            </Button>
          </ButtonToolbar>
        </Col>
      </>
    );
  };

  renderFields() {
    const { controlsDisabled, currentOrganization } = this.state;
    const { isGroupMember, organizationsList, principalPartner, cumulativeVolumes, tiers_type } =
      this.props;

    return (
      <div className="x_content">
        <Row>
          <Col xs={10} style={{ marginLeft: "20px" }}>
            <Field
              name="name"
              component={FormTextRowField}
              type="text"
              label="Display Name"
              placeholder="Name"
              labelStyle={{ xs: 12, md: 2, sm: 2 }}
              controlStyle={{ xs: 12, md: 9, sm: 9 }}
              inputWidth="345px"
              validate={[required, minLength(1), maxLength(50)]}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={10} style={{ marginLeft: "20px" }}>
            <Field
              name="urn"
              label="Short Name"
              type="text"
              component={FormTextRowField}
              placeholder=""
              labelStyle={{ xs: 12, md: 2, sm: 2 }}
              controlStyle={{ xs: 12, md: 9, sm: 9 }}
              inputWidth="345px"
              required
              validate={[required, isValidUrn]}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4} xsOffset={1}>
            <Field
              checkboxesMarginEnabled
              name="enabled"
              component={FormCheckboxField}
              onChange={this.handleControlsChange}
              label="Enabled"
              colLg={5}
              colMd={5}
              colSm={5}
            />
          </Col>
          <Col xs={3}>
            <Field
              checkboxesMarginEnabled
              name="filtering"
              component={FormCheckboxField}
              disabled={controlsDisabled}
              label="Filtering"
              colMd={9}
              colLg={9}
              colSm={9}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4} xsOffset={1}>
            <Field
              checkboxesMarginEnabled
              name="isApiAccessEnabled"
              component={FormCheckboxField}
              disabled={controlsDisabled || !currentOrganization.hasCredentials}
              label={
                <>
                  API Access{" "}
                  {!currentOrganization.hasCredentials && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="api-access-info">
                          Organization is not generated credentials yet. Create it first to
                          enable/disable.
                        </Tooltip>
                      }
                      delayShow={250}
                      delayHide={150}
                    >
                      <Glyphicon glyph="question-sign" />
                    </OverlayTrigger>
                  )}
                </>
              }
              colLg={5}
              colMd={5}
              colSm={5}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4} xsOffset={1}>
            <Field
              checkboxesMarginEnabled
              name="main"
              component={FormCheckboxField}
              disabled={controlsDisabled}
              label="Main"
              colLg={5}
              colMd={5}
              colSm={5}
            />
          </Col>
          <Col xs={3}>
            <Field
              checkboxesMarginEnabled
              name="ptag"
              component={FormCheckboxField}
              disabled={controlsDisabled}
              label="PTag"
              colMd={9}
              colLg={9}
              colSm={9}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4} xsOffset={1}>
            <Field
              checkboxesMarginEnabled
              name="mainv2"
              component={FormCheckboxField}
              disabled={controlsDisabled}
              label="MainV2"
              colLg={5}
              colMd={5}
              colSm={5}
            />
          </Col>
          <Col xs={3}>
            <Field
              checkboxesMarginEnabled
              name="ptagv2"
              component={FormCheckboxField}
              disabled={controlsDisabled}
              label="PTagV2"
              colMd={9}
              colLg={9}
              colSm={9}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4} xsOffset={1}>
            <Field
              checkboxesMarginEnabled
              name="referral"
              component={FormCheckboxField}
              disabled={controlsDisabled}
              label="Referral"
              colLg={5}
              colMd={5}
              colSm={5}
            />
          </Col>
          <Col xs={3}>
            <Field
              checkboxesMarginEnabled
              name="ptqs"
              component={FormCheckboxField}
              disabled={controlsDisabled}
              label="PTQS"
              colMd={9}
              colLg={9}
              colSm={9}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4} xsOffset={1}>
            <Field
              checkboxesMarginEnabled
              name="send_email_alerts.send_alert"
              component={FormCheckboxField}
              disabled={controlsDisabled}
              label="Send filtering alerts"
              colLg={5}
              colMd={5}
              colSm={5}
            />
          </Col>
          <Col xs={3}>
            <Field
              checkboxesMarginEnabled
              name="tempest_coverage"
              component={FormCheckboxField}
              disabled={controlsDisabled}
              label="Tempest Coverage"
              colMd={9}
              colLg={9}
              colSm={9}
            />
          </Col>
        </Row>

        {this.props.showThreshold && (
          <>
            <Row>
              <Col xs={3} style={{ marginLeft: "117px" }}>
                <Field
                  name="filtering_threshold"
                  component={FormTextRowField}
                  type="text"
                  label="Threshold"
                  placeholder="Ex: 35%"
                  labelStyle={{ xs: 12, md: 2, lg: 5, sm: 2 }}
                  controlStyle={{ xs: 12, md: 9, lg: 6, sm: 9 }}
                  inputWidth="110px"
                  validate={[required, minLength(1), maxLength(10)]}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={10} style={{ marginLeft: 20 }}>
                <FieldArray name="send_email_alerts.emails" component={this.renderEmails} />
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col xs={10} style={{ marginLeft: "20px" }}>
            <Field
              name="distributionpartnercode"
              component={FormTextRowField}
              type="text"
              label="PartnerCode"
              placeholder="ATXY"
              labelStyle={{ xs: 12, md: 2, sm: 2 }}
              controlStyle={{ xs: 12, md: 9, sm: 9 }}
              inputWidth="345px"
              validate={[required, minLength(1), maxLength(10)]}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={10} style={{ marginLeft: "20px" }}>
            <Field
              name="revshare"
              component={FormTextRowField}
              type="text"
              label="RevShare"
              placeholder="Ex: 70%"
              labelStyle={{ xs: 12, md: 2, sm: 2 }}
              controlStyle={{ xs: 12, md: 9, sm: 9 }}
              inputWidth="345px"
              onClick={this.handleOnLinkClick}
              validate={[minLength(1), maxLength(10)]}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={10} style={{ marginLeft: "20px" }}>
            <Field
              name="tiers_type"
              label="Tiers Type"
              component={FormSelectField}
              options={TIERS_TYPES.map((tiersType) => ({
                value: tiersType,
                label: TIERS_TYPE_NAME_MAPPING[tiersType],
              }))}
              disabled={principalPartner && cumulativeVolumes}
            />
          </Col>
        </Row>

        {tiers_type !== "none" && (
          <Row>
            <Col xs={10} style={{ marginLeft: "20px" }}>
              <FieldArray
                name={`${tiers_type}_tiers`}
                component={renderTiers}
                disabled={principalPartner && cumulativeVolumes}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={8}>
            <Field
              name="group_principal_partner_code"
              component={FormTextRowField}
              formGroupStyle={{ marginLeft: 15 }}
              type="text"
              label="PrincipalPartner"
              placeholder="ATXX, or leave empty when not applicable"
              labelStyle={{ xs: 12, sm: 4, md: 4, lg: 4 }}
              controlStyle={{ xs: 12, sm: 8, md: 8, lg: 8 }}
              inputWidth="345px"
              validate={[
                minLength(1),
                maxLength(10),
                (value) =>
                  !value || organizationsList.find((org) => org.id === value)
                    ? false
                    : "Organization not found",
              ]}
            />
          </Col>
          <Col xs={4} style={{ marginLeft: -38 }}>
            <Field
              name="cumulative_volumes"
              component={FormCheckboxField}
              disabled={controlsDisabled || !isGroupMember}
              label="Cumulative Volumes"
              lg={12}
              md={12}
              sm={12}
              xs={12}
              isFrontLabel={false}
            />
          </Col>
          {/* <Field
            name="group_principal_partner_code"
            labelName="Group principal partner"
            component={UserOrganizationsField}
            organizations={this.props.organizationsList}
          /> */}
        </Row>

        <div className="x_title">
          <h2>Add formcodes</h2>
          <div className="clearfix" />
        </div>
        <FieldArray
          name="formCodes"
          component={renderFormCodes}
          validate={[(value) => (!value.length ? "Need at least one formcode" : null)]}
        />
      </div>
    );
  }

  render() {
    const { handleSubmit, onCancel, management } = this.props;
    const { isFetching, isLoading } = this.state;

    return (
      <Modal show animation={false} bsSize="lg">
        <Form horizontal onSubmit={handleSubmit(this.onSubmit)}>
          <Modal.Body>
            <Row>
              <div className="x_title">
                <h2>{this.isUpdate() ? "Update" : "Add"} organization</h2>
                <div className="clearfix" />
              </div>
              {isFetching ? <Loader /> : this.renderFields()}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col xs={12}>
              <ButtonToolbar className="pull-right">
                <Button type="submit" bsStyle="success" disabled={isLoading || !management}>
                  {this.isUpdate() ? "Update" : "Create"}
                </Button>
                <Button onClick={() => onCancel()} bsStyle="danger">
                  Cancel
                </Button>
              </ButtonToolbar>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

OrganizationDialog.propTypes = {
  id: PropTypes.string,

  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  let { isFetching, success } = getCurrentOrganizationFetchStatus(state);
  const management = getManagementStatus(state);
  const organizationsList = ownProps.organizationsList;

  const getValue = formValueSelector(FORM_NAME);
  const getTouched = isDirty(FORM_NAME);

  const groupPrincipalPartnerCode = getValue(state, "group_principal_partner_code");
  const principalPartner = organizationsList.find((org) => org.id === groupPrincipalPartnerCode);

  return {
    isFetching,
    success,
    management,
    organizationsList,
    showThreshold: getValue(state, "send_email_alerts.send_alert"),
    isGroupMember: Boolean(groupPrincipalPartnerCode),
    revshare: getValue(state, "revshare"),
    tiers_type: getValue(state, "tiers_type"),
    revshare_tiers: getValue(state, "revshare_tiers"),
    gross_rev_tiers: getValue(state, "gross_rev_tiers"),
    search_count_tiers: getValue(state, "search_count_tiers"),
    isRevshareUpdated: getTouched(state, "revshare"),
    cumulativeVolumes: Boolean(getValue(state, "cumulative_volumes")),
    principalPartner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeForm: (organization) => dispatch(initialize(FORM_NAME, organization)),
    destroyForm: (form) => dispatch(destroy(form)),
    changeValue: (field, value) => dispatch(change(FORM_NAME, field, value)),

    updateOrganizationsList: (organization) => {
      dispatch(
        organizationsApi.util.updateQueryData("getOrganizations", undefined, (organizations) => {
          const index = organizations.findIndex(({ id }) => id === organization.id);

          if (index >= 0) {
            organizations[index] = organization;
          } else {
            organizations.push(organization);
          }
        })
      );
      // needed to update `auth.organizations`
      dispatch(pushOrganization(organization));
    },
  };
};

OrganizationDialog = connect(mapStateToProps, mapDispatchToProps)(OrganizationDialog);

export default OrganizationDialog = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  validate: ({ send_email_alerts, tiers_type, ...restValues }) => {
    return {
      ...validateUniqEmail(send_email_alerts),
      ...validateTiers(restValues[`${tiers_type}_tiers`], tiers_type),
    };
  },
})(OrganizationDialog);

const urnRegexp = /^[a-z0-9.:_\-]+$/i;
const isValidUrn = (urn) => {
  return urn.match(urnRegexp) ? undefined : (
    <span>
      Only letters, numbers and ._:- are allowed
      <br />
      Example if the organization name is 'TravelAudience', urn can be: 'travelaudience' or
      'travel_audience'
    </span>
  );
};
