import { DEFAULT_TIERS } from "../constants";

export interface FormTier {
  revshare: string;
  limit: string;
}

export interface Tier {
  revshare: string;
  limit: number;
}

export const TIERS_TYPES = ["revshare", "gross_rev", "search_count", "none"] as const;

export type TiersType = (typeof TIERS_TYPES)[number];

export type Tiers = Record<string, Tier>;

export interface TiersFrontendData {
  tiers_type: TiersType;
  revshare_tiers: FormTier[];
  gross_rev_tiers: FormTier[];
  search_count_tiers: FormTier[];
}

export interface TiersBackendData {
  revshare_tiers: Tiers | null;
  gross_rev_tiers: Tiers | null;
  search_count_tiers: Tiers | null;
}

export const TIERS_TYPE_NAME_MAPPING: Record<TiersType, string> = {
  revshare: "Revenue Share",
  gross_rev: "Revenue Cumulation",
  search_count: "Search Cumulation",
  none: "None",
};

export const tiersObjectToArray = (tiers: Tiers | null): FormTier[] => {
  return tiers && typeof tiers === "object"
    ? Object.values(tiers).map((tier) => ({ revshare: tier.revshare, limit: String(tier.limit) }))
    : Object.values(DEFAULT_TIERS).map((tier) => ({
        revshare: tier.revshare,
        limit: String(tier.limit),
      }));
};

export const getTiersData = (data: TiersBackendData): TiersFrontendData => {
  const tiers_type =
    (["revshare", "gross_rev", "search_count"] as const).find(
      (type) => data[`${type}_tiers`] && typeof data[`${type}_tiers`] === "object"
    ) || "none";

  return {
    tiers_type,
    revshare_tiers: tiersObjectToArray(data.revshare_tiers),
    gross_rev_tiers: tiersObjectToArray(data.gross_rev_tiers),
    search_count_tiers: tiersObjectToArray(data.search_count_tiers),
  };
};

const tiersArrayToObject = (tiers: FormTier[]): Tiers => {
  let result: Tiers = {};

  tiers.forEach((tier, index) => {
    result[`tier${index}`] = {
      revshare: Number(tier.revshare).toFixed(2),
      limit: Number(tier.limit),
    };
  });

  return result;
};

export const getBackendTiers = ({
  tiers_type,
  revshare_tiers,
  gross_rev_tiers,
  search_count_tiers,
}: TiersFrontendData): TiersBackendData => {
  switch (tiers_type) {
    case "revshare":
      return {
        revshare_tiers: tiersArrayToObject(revshare_tiers),
        gross_rev_tiers: null,
        search_count_tiers: null,
      };
    case "gross_rev":
      return {
        revshare_tiers: null,
        gross_rev_tiers: tiersArrayToObject(gross_rev_tiers),
        search_count_tiers: null,
      };
    case "search_count":
      return {
        revshare_tiers: null,
        gross_rev_tiers: null,
        search_count_tiers: tiersArrayToObject(search_count_tiers),
      };
    default:
      return {
        revshare_tiers: null,
        gross_rev_tiers: null,
        search_count_tiers: null,
      };
  }
};

export const validateUniqEmail = (send_email_alerts?: { emails: string[] }) => {
  const { emails } = send_email_alerts || {};

  if (!emails || !emails.length) return {};

  const uniqEmailValidationMessages = emails.map((value, index) =>
    [...emails.slice(0, index), ...emails.slice(index + 1)].includes(value)
      ? "Should be unique email address"
      : false
  );

  if (uniqEmailValidationMessages.some((msg) => typeof msg === "string")) {
    return {
      send_email_alerts: {
        emails: uniqEmailValidationMessages,
      },
    };
  }

  return {};
};

export const validateTiers = (values: FormTier[], tiersType: TiersType) => {
  if (tiersType === "none") return {};

  const negativeLimitErrorMessages = values.map((el, index, array) =>
    index === array.length - 1 || Number(el.limit) > 0
      ? undefined
      : {
          limit: "Limit cannot be a negative value",
        }
  );

  if (negativeLimitErrorMessages.some((msg) => msg)) {
    return {
      [`${tiersType}_tiers`]: negativeLimitErrorMessages,
    };
  }

  const revsharesOrderErrorMessages = values.map((el, index, array) =>
    index === 0 || Number(el.revshare) > Number(array[index - 1].revshare)
      ? undefined
      : {
          revshare: "Revshares cannot be in descending order",
        }
  );

  if (revsharesOrderErrorMessages.some((msg) => msg)) {
    return {
      [`${tiersType}_tiers`]: revsharesOrderErrorMessages,
    };
  }

  const limitsOrderErrorMessages = values.map((el, index, array) =>
    index >= array.length - 2 || Number(el.limit) < Number(array[index + 1].limit)
      ? undefined
      : {
          limit: "Limits cannot be in descending order",
        }
  );

  if (limitsOrderErrorMessages.some((msg) => msg)) {
    return {
      [`${tiersType}_tiers`]: limitsOrderErrorMessages,
    };
  }

  if (Number(values[values.length - 1].limit) !== -1) {
    return {
      [`${tiersType}_tiers`]: values.map((_el, index) =>
        index === values.length - 1
          ? {
              limit: "Last limit should be -1",
            }
          : undefined
      ),
    };
  }

  return {};
};
